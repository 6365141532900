import {
  Form, Input, Modal, Select, Spin, Table,
} from 'antd';
import React, { useEffect } from 'react';
import { ContextModalProps, ChangeMarkerContextModalProps } from './types';
import { FormModeEnum } from '../../../models/enums';
import { SessionContext } from '../../../models/types';
import SelectMarker from '../../simple/SelectMarker';

export const AddEditContextModal = ({
  isVisible, modeForm, initData, onSubmit, onCancel,
}:ContextModalProps<SessionContext>) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initData);
  }, [form, initData]);

  return (
    <Modal
      title={modeForm === FormModeEnum.Create ? 'Добавление контекста' : 'Редактирование контекста'}
      open={isVisible}
      okText={modeForm === FormModeEnum.Create ? 'Добавить' : 'Изменить'}
      cancelText="Отменить"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={initData}
      >
        <Form.Item hidden name="id" required={false}>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Название"
          rules={[
            {
              required: true, message: 'Необходимо добавить название контекста',
            },
            {
              message: 'Токен должен быть длинной до 255 символов', max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const AddEditMarkerContextModal = ({
  isVisible, modeForm, initContext, initMarker, onSubmit, onCancel,
}:ChangeMarkerContextModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // из за модального окна, фокус с initValues не пройдет
    form.setFieldsValue(initMarker);
  }, [isVisible]);

  return (
    <Modal
      title={
        modeForm === FormModeEnum.Create ? `Добавление события в контекст: "${initContext?.name}"` : `Редактирование события в контексте: "${initContext?.name}"`
}
      // forceRender
      open={isVisible}
      okText={modeForm === FormModeEnum.Create ? 'Добавить' : 'Изменить'}
      cancelText="Отменить"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log(values);
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        // preserve={false}
        layout="horizontal"
        name="form_in_modal"
      >
        <Form.Item hidden name="id" required={false}>
          <Input />
        </Form.Item>
        <Form.Item
          name="marker"
          label="Событие"
          rules={[
            {
              required: true, message: 'Необходимо выбрать маркер',
            },
          ]}
        >
          <SelectMarker />
        </Form.Item>
        <Form.Item
          name="show_num"
          label="Порядковый номер"
          rules={[
            {
              required: true, message: 'Необходимо ввести число',
            },
            {
              pattern: RegExp('^\\d+$'),
              message: 'Введите число',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
