import React, { useEffect } from 'react';
import {DatePicker, Form, Modal, notification, TimePicker} from 'antd';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { Space } from 'antd/lib';
import { AddEventDialogData, AddEventDialogProps } from './types';
import SelectMarker from '../../simple/SelectMarker';
import appDateFormat, {appTimeFormatWithSecond} from "../../../consts";


const AddEventDialog = ({
  initialValues,
  isShow,
  onSubmit,
  onCancel,
}: AddEventDialogProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      start_event_time: dayjs(initialValues.event_start),
      start_event_date: dayjs(initialValues.event_start),
      end_event_time: dayjs(initialValues.event_end),
      end_event_date: dayjs(initialValues.event_end),
    });
  }, [initialValues]);

  return (
    <Modal
      title="Добавление события"
      open={isShow}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields();
            const date: AddEventDialogData = {
              event_start: new Date(
                values.start_event_date.year(),
                values.start_event_date.month(),
                values.start_event_date.date(),
                values.start_event_time.hour(),
                values.start_event_time.minute(),
                values.start_event_time.second()
              ),
              event_end: new Date(
                values.end_event_date.year(),
                values.end_event_date.month(),
                values.end_event_date.date(),
                values.end_event_time.hour(),
                values.end_event_time.minute(),
                values.end_event_time.second()
              ),
              marker: values.marker,
              notes: values.notes,
            };
            if (date.event_start<date.event_end) {
              onSubmit(date);
            }else{
              notification.error({ message: 'Дата начала события должно быть меньше времени окончания'});
            }
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      onCancel={() => {
        onCancel();
      }}
      okText="Добавить"
      cancelText="Отменить"
    >
      <Form
        form={form}
        labelCol={{ span: 8}}
        name="form_in_modal"
        initialValues={{
          start_event_time: dayjs(initialValues.event_start),
          start_event_date: dayjs(initialValues.event_start),
          end_event_time: dayjs(initialValues.event_end),
          end_event_date: dayjs(initialValues.event_end),
        }}
      >
        <Form.Item
          name="marker"
          label="Событие"
          rules={[
            {
              required: true,
              message: 'Необходимо выбрать событие',
            },
          ]}
        >
          <SelectMarker />
        </Form.Item>
        <Form.Item
          label="Начало события"
        >
          <Space align="baseline">
            <Form.Item
              name="start_event_time"
              rules={[
                {
                  required: true,
                  message: 'Необходимо добавить время начала события',
                },
              ]}
            >
              <TimePicker
                format={appTimeFormatWithSecond}
                placeholder="Время начала"
              />
            </Form.Item>
            <Form.Item
              name="start_event_date"
              rules={[
                {
                  required: true,
                  message: 'Необходимо добавить дату начала события',
                },
              ]}
            >
              <DatePicker format={appDateFormat} placeholder="Дата начала"/>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label="Окончание события"
        >
          <Space align="baseline">
            <Form.Item
              name="end_event_time"
              rules={[
                {
                  required: true,
                  message: 'Необходимо добавить время окончания события',
                },
              ]}
            >
              <TimePicker format={appTimeFormatWithSecond} placeholder="Время окончания"/>
            </Form.Item>
            <Form.Item
              name="end_event_date"
              rules={[
                {
                  required: true,
                  message: 'Необходимо добавить дату окончания события',
                },
              ]}
            >
              <DatePicker format={appDateFormat} placeholder="Дата окончания"/>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item name="notes" label="Комментарий">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEventDialog;
