import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

import dayjs from 'dayjs';
import {SessionSpectrTimeGraph, SessionTimeData, SessionTimeGraphProps} from './types';
import {PPGData, PPGDataMeasure, SpectrData} from '../../../models/types';
import { appTimeFormatWithSecond} from "../../../consts";
import {findClosestDateIndex} from "../../../models/helper";
import {MARKER_ACUTE_STRESS} from "../../../core/constants/markers";
import SPECTR_INTERVAL from "../../../core/constants/time_limits";

const PPGTimeGraph: React.FC<SessionTimeGraphProps<SessionTimeData>> = ({
  timeData:{
    queryFn,
    minX,
    maxX,
    minY,
    maxY,
  },
  chartName,
  graphRangeX,
  setGraphRangeX,
}) => {

  const [
    markerRange, setMarkerRange
  ]  = useState<Array<am5.DataItem<am5xy.IDateAxisDataItem>>>([])

  const chartRef = useRef<am5xy.XYChart | null>(null);
  const seriesRefGreen = useRef<am5xy.LineSeries | null>(null);
  const seriesRefRed = useRef<am5xy.LineSeries | null>(null);
  const seriesRefIR = useRef<am5xy.LineSeries | null>(null);
  const xAxisRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer> | null>(null);

  const [ppgCount, setPpgCount]= useState<number>();

  const [cursorDT, setCursorDT] = useState<Date>();
  const [cursorLF, setCursorLF] = useState<number>();
  const [cursorHF, setCursorHF] = useState<number>();
  const [cursorTP, setCursorTP] = useState<number>();
  const [cursorLFHF, setCursorLFHF] = useState<number>();

  // чтобы получить на графике мгновенные значения LF, HF - мы их запомним и будем искать самостоятельно
  const dataX :Date[] = []
  const dataGreen :any[] = []
  const dataRed :any[] = []
  const dataIR :any[] = []

  useLayoutEffect(() => {
    // создаем основу графика
    const root = am5.Root.new(chartName);

    // прячем лого
    root._logo?.dispose();

    // навешиваем тему
    root.setThemes([am5themes_Animated.new(root)]);

    // создаем отрисовку
    const chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    chartRef.current = chart

    // создадим рамку на графике
    const background = chart.plotContainer.get("background");
    if (background){
      background.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
        stroke: am5.color('#000'),
        fill: am5.color(0xffffd1),
        fillOpacity: 1.0,
      })
    }

    // отключим кнопку ZOOM
    chart.zoomOutButton.set("forceHidden", true);

    // создаем оси
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        min: minX.getTime(),
        max: maxX ? maxX.getTime() : undefined,
        strictMinMax: true,
        baseInterval: {
          timeUnit: 'millisecond',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );
    xAxisRef.current = xAxis;

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // min: minY,
        min: 0  ,
        // max: maxY,
        // max: 3710000,
        renderer: am5xy.AxisRendererY.new(root, {
          // отпция застравляет отрисовывать ось Y внутри графика
          inside: true,
          brightness: 1,
        }),
        // фиксим ошибку, что внутри метки не видны при полной непрозрачности
        layer:1,
      }),
    );

    yAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x000000),
      dy:10,
      // opacity: 1.0,
      // fillOpacity: 1.0,
      // brightness: 1,
    });

    // создаем серии
    const seriesGreen = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0x00FF00),
      }),
    );
    const seriesRed = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0xff0000),
      }),
    );
    const seriesIR = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0x000000),
      }),
    );

    // создадим курсор
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
      }),
    );

    // // будем отслеживать положение курсора
    // cursor.events.on("cursormoved", (ev) => {
    //   const x = ev.target.getPrivate("positionX");
    //
    //   if (x) {
    //     const dateX = xAxis.positionToDate(xAxis.toAxisPosition(x));
    //
    //     setCursorDT(dateX);
    //
    //     // пробуем вывести LF, HF
    //     if (dataX) {
    //       const xIndex = findClosestDateIndex(dataX, dateX);
    //       if (xIndex>-1){
    //         // console.log('dateX',dateX, 'xIndex', xIndex);
    //         setCursorLF(Number(dataLF?.at(xIndex)?.toFixed(2)))
    //         setCursorHF(Number(dataHF?.at(xIndex)?.toFixed(2)))
    //         setCursorTP(Number(dataTP?.at(xIndex)?.toFixed(2)));
    //         setCursorLFHF(Number(dataLFHF?.at(xIndex)?.toFixed(2)));
    //       }
    //     }
    //   }
    // })

    seriesRefGreen.current = seriesGreen;
    seriesRefRed.current = seriesRed;
    seriesRefIR.current = seriesIR;

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    // стартуем обновлялку сигнала - раз в 5 секунд
    const updateData = async () => {
      try {
        // console.log('Update', chartName);
        const data = await queryFn();
        const ppgData = data.data as PPGDataMeasure[];

        dataX.length = 0;
        dataGreen.length = 0;
        dataRed.length = 0;
        dataIR.length = 0;

        ppgData.forEach((item) => {
          const dt = am5.time
            .add(dayjs(item.dt).toDate(), 'millisecond', 1)
            .getTime();
          // для отображения временных данных
          dataX.push(dayjs(item.dt).toDate())
          // dataGreen.push(item.green)
          dataGreen.push({
            date: dt,
            value: item.g,
          });
          dataRed.push({
            date: dt,
            value: item.r,
          })
          dataIR.push({
            date: dt,
            value: item.i,
          })
        });

        seriesRefGreen.current?.data.setAll(dataGreen);
        seriesRefRed.current?.data.setAll(dataRed);
        seriesRefIR.current?.data.setAll(dataIR);
        setPpgCount(dataGreen.length);

      } catch (e) {
        console.error(e);
      } finally {
        // setTimeout(() => {
        //   updateData();
        // }, 1000);
      }
    };

    updateData();
  }, []);

  // /* Тут мы делаем переотрисовку маркеров */
  // useEffect(() => {
  //
  //   const newMarkerRange: Array<am5.DataItem<am5xy.IDateAxisDataItem>> = []
  //
  //   // сначала удалим все, что навставляли
  //   markerRange.forEach((item) => {
  //     xAxisRef.current?.axisRanges.removeValue(item);
  //   })
  //
  //   // потом вставим
  //   markers.forEach((item) => {
  //     if (markerIdsVisible.includes(item.marker_id)) {
  //
  //       const markerItem = xAxisRef.current?.makeDataItem({});
  //
  //       // для маркеров острого стресса мы делаем сдвиг
  //       let startDt = item.start_dt
  //       if (item.marker_id === MARKER_ACUTE_STRESS){
  //         console.log(startDt, dayjs(startDt).add(SPECTR_INTERVAL,'millisecond').toDate())
  //         startDt = dayjs(startDt).add(SPECTR_INTERVAL,'millisecond').toDate()
  //       }
  //
  //       markerItem?.set(
  //         'value',
  //         am5.time.add(startDt, 'millisecond', 1).getTime(),
  //       );
  //       if (item.end_dt) {
  //
  //         // для маркеров острого стресса мы делаем сдвиг
  //         let endDt = item.end_dt
  //         if (item.marker_id === MARKER_ACUTE_STRESS){
  //           endDt = dayjs(endDt).add(SPECTR_INTERVAL,'millisecond').toDate()
  //         }
  //
  //         markerItem?.set(
  //           'endValue',
  //           am5.time.add(endDt, 'millisecond', 1).getTime(),
  //         );
  //       }
  //       if (markerItem) {
  //         const range = xAxisRef.current?.createAxisRange(markerItem);
  //         if (range) {
  //           newMarkerRange.push(range);
  //         }
  //         markerItem.get('axisFill')?.setAll({
  //           fill: chartRef.current?.get('colors')?.getIndex(12),
  //           fillOpacity: 1,
  //           visible: true,
  //         });
  //       }
  //     }
  //   });
  //
  //   setMarkerRange(newMarkerRange);
  //
  // }, [markerIdsVisible, markers])


  useEffect(() => {
    if (!setGraphRangeX) {
      if (graphRangeX) {
        xAxisRef.current?.setAll(graphRangeX);
      }
    }
  }, [graphRangeX]);

  return (
    <>
      <div>
        Name: <b>PPG</b>,
        points: <b>{ppgCount}</b>,
        Legends: <b>IR</b>, <span style={{color: "red"}}><b>RED</b></span>,
        <span style={{color: "green"}}><b>GREEN</b></span>
      </div>
      <div id={chartName} style={{ width: '100%', height: '250px' }} />
    </>
  )

};

export default PPGTimeGraph;
