import React from 'react';
import {
    Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, TimePicker, Typography
} from 'antd';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { TelemetrySearchProps } from './types';
import api from '../../../http';
import {Marker, SessionContextExtra, SessionContextNameExtra} from '../../../models/types';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const TelemetrySearchForm: React.FC<TelemetrySearchProps> = (
    {
      nick,
      setNick,
      gender,
      setGender,
      contexts,
      setContexts,
      markers,
      setMarkers,
      oldStart,
      setOldStart,
      oldEnd,
      setOldEnd
    }) => {
  const [form] = Form.useForm();

  const useFilterData = () => {
    const {
      isLoading: contextsIsLoading,
      error: contextsDataError,
      data: contextsData,
    } = useQuery(['contextsExtraList'], () => api.v1.v1ContextsListContextNamesExtra());
    const {
      isLoading: markersIsLoading,
      error: markersDataError,
      data: markersData,
    } = useQuery(['markersList'], () => api.v1.v1MarkersList());

    const isError = contextsDataError || markersDataError;
    const isLoading = contextsIsLoading || markersIsLoading;

    return {
      isError,
      isLoading,
      contextsData,
      markersData
    };
  };

  const {
    isError,
    isLoading,
    contextsData,
    markersData
  } = useFilterData();

  if (isError && axios.isAxiosError(isError)) {
    const err: AxiosError = isError as AxiosError;
    console.log('isError', err.message);
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  const onFinish = (values: any) => {
    setNick(values.nick);
    setGender(values.gender);
    setContexts(values.contexts);
    setMarkers(values.markers);
    setOldStart(values.old_start);
    setOldEnd(values.old_end);
  };

  const onReset = () => {
    console.log('Reset!!!!')
    setOldStart(undefined);
    setOldEnd(undefined);
  };

  const handleContextsChange = (value: string[]) => {
    console.log(`contexts selected ${value}`);
  };

  const handleMarkersChange = (value: string[]) => {
    console.log(`markers selected ${value}`);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        "nick": nick,
        "contexts": contexts,
        "markers": markers,
        "gender": gender,
        "old_start": oldStart,
        "old_end": oldEnd
      }}
    >
          <Form.Item
              label={<Text strong>Обьект:</Text>}
          name="nick"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<Text strong>Контексты:</Text>}
            name="contexts"
          >
            <Select
              mode="multiple"
              allowClear
              optionFilterProp="value"
              onChange={handleContextsChange}
              options={
                (contextsData?.data.names as string[]).map(
                  (name) => { return { value: name  }},
                )
              }
            />
          </Form.Item>
          <Form.Item
          label={<Text strong>События:</Text>}
          name="markers"
          >
            <Select
                mode="multiple"
                allowClear
                optionFilterProp="label"
                onChange={handleMarkersChange}
                options={
                  (markersData?.data as Marker[]).map(
                      (item) => { return { value: item.id, label: item.name }; },
                  )
                }
            />
          </Form.Item>
          <Form.Item label={<Text strong>Возраст (лет):</Text>} style={{ marginBottom: 0 }}>
            <Form.Item
                name="old_start"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                rules={[
                    {
                        type: 'number',
                        required: false,
                        message: 'Введите цифру от 1 до 100',
                    },
                ]}
            >
                <InputNumber min={1} max={99} placeholder="от" />
            </Form.Item>
            <Form.Item
                name="old_end"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
            >
              <InputNumber min={1} max={99} placeholder="до" />
            </Form.Item>
          </Form.Item>
          <Form.Item
              label={<Text strong>Пол:</Text>}
              name="gender"
          >
            <Select
                // style={{ width: 120 }}
                allowClear
                options={[
                  { value: '1', label: 'Мужской' },
                  { value: '2', label: 'Женский' },
                ]}
            />
          </Form.Item>
          <Form.Item
              label={<Text strong>Дата измерения</Text>}
              name="data_measure"
          >
            <RangePicker allowEmpty={[true, true]}/>
          </Form.Item>
          <Form.Item
              label={<Text strong>Время интервала</Text>}
              name="time_interval"
          >
            <TimePicker.RangePicker allowEmpty={[true, true]}/>
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Поиск
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={onReset}
          >
            Сброс
          </Button>
    </Form>
  );
};

export default TelemetrySearchForm;
