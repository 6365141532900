import React from 'react';
import { Table, Image } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import polevayImg from '../assets/images/team/polevay.jpg';
import parinImg from '../assets/images/team/parin.jpg';
import baxcinaImg from '../assets/images/team/baxcina.jpg';
import necrasovaImg from '../assets/images/team/nekrasova.jpg';
import ereminImg from '../assets/images/team/eremin.png';
import bulanovImg from '../assets/images/team/bulanov.png';
import shishalovImg from '../assets/images/team/shishalov.jpg';

interface DataType {
  key: string,
  image: string,
  fio: string,
  description: string,
  email?:string
}

const About = () => {
  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => (
        <Image src={record.image} height={300} />
      ),
    },
    {
      title: '',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <>
          <b>{record.fio}</b>
          <p>{record.description}</p>
          {record.email && <div><b>Email:</b>&nbsp;{record.email}</div>}
        </>
      ),
    },
  ];

  const scintists: DataType[] = [
    {
      key: 'polevay',
      image: polevayImg,
      fio: 'Полевая Софья Александровна',
      description: 'Научный руководитель проекта, д.б.н., зав. каф. психофизиологии ННГУ им. Н.И. Лобачевского.',
      email: 'sofia.polevaia@fsn.unn.ru',
    },
    {
      key: 'parin',
      image: parinImg,
      fio: 'Парин Сергей Борисович',
      description: 'Д.б.н., проф. каф. психофизиологии, зав. лаб. когнитивной психофизиологии ННГУ им. Н.И. Лобачевского.',
    },
    {
      key: 'baxcina',
      image: baxcinaImg,
      fio: 'Бахчина Анастасия Владимировна',
      description: 'н.с. лаборатории психофизиологии им. В.Б. Швыркова ИП РАН (Москва)',
    },
    {
      key: 'necrasova',
      image: necrasovaImg,
      fio: 'Некрасова Марина Михайловна',
      description: 'с. н.с. лаборатории психофизиологических исследований и здоровьесберегающих технологий ННИГП',
    },
  ];

  const its: DataType[] = [
    {
      key: 'eremin',
      image: ereminImg,
      fio: 'Еремин Евгений Викторович',
      description: 'Главный разработчик Web-платформы КОГНИТОМ, асс.каф. психофизиологии ННГУ',
    },
    {
      key: 'bulanov',
      image: bulanovImg,
      fio: 'Буланов Никита Алексеевич',
      description: 'Разработчик когнитивного тренажера, асп. НИУ «Высшая школа экономики» (Москва)',
    },
    {
      key: 'shishalov',
      image: shishalovImg,
      fio: 'Шишалов',
      description: 'Научный консультант телеметрии ритма сердца, сотрудник ООО «Харман» (Нижний Новгород)',
    },
  ];

  const titleScintists = () => 'Научная группа';
  const titleIT = () => 'IT группа';

  return (
    <>
      <h1>Команда проекта</h1>
      <Table columns={columns} dataSource={scintists} bordered showHeader={false} title={titleScintists} />
      <Table columns={columns} dataSource={its} showHeader={false} title={titleIT} />
    </>
  );
};

export default About;
