import React, { useState } from 'react';
import DebounceSelect from '../DebounceSelect/Index';
import { UserSelectProps, CarrierSelectValue } from './types';
import api from '../../../http';

const SelectCarrier: React.FC<UserSelectProps> = ({ carrier, setCarrier }) => {
  const fetchCarrierList = async (nick: string): Promise<CarrierSelectValue[]> => {
    // TODO разобраться с nick
    const response = await api.v1.v1CarriersListList();

    const carrierSelect: CarrierSelectValue[] = [];

    for (let i = 0; i < response.data.length; i += 1) {
      const carrierId = response.data[i].id;
      if (carrierId) {
        carrierSelect.push({ value: carrierId, label: response.data[i].nick });
      }
    }

    return carrierSelect;
  };

  return (
    <DebounceSelect
      value={carrier}
      allowClear
      placeholder="Выберите испытуемого"
      fetchOptions={fetchCarrierList}
      onChange={(newValue) => {
        setCarrier(newValue as CarrierSelectValue);
      }}
    />
  );
};

export default SelectCarrier;
