import { Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { TrustedEmails } from '../../../models/types';
import { FormModeEnum } from '../../../models/enums';
import { TrustedEmailsModalProps } from './types';

const AddEditTrustedEmailsModal = ({
  isVisible, modeForm, initData, onSubmit, onCancel,
}:TrustedEmailsModalProps<TrustedEmails>) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initData);
  }, [form, initData]);

  return (
    <Modal
      title={modeForm === FormModeEnum.Create ? 'Добавление Email' : 'Редактирование Email'}
      open={isVisible}
      okText={modeForm === FormModeEnum.Create ? 'Добавить' : 'Изменить'}
      cancelText="Отменить"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={initData}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item hidden name="id" required={false}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Введите Email', type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="info"
          label="Дополнительная информация"
          rules={[
            {
              message: 'Информация должена быть длинной до 255 символов', max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditTrustedEmailsModal;
