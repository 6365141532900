import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import dayjs from 'dayjs';
import { SessionTimeData, SessionTimeGraphProps } from './types';
import { HRDataMeasure } from '../../../models/types';

const EnergyExpTimeGraph: React.FC<SessionTimeGraphProps<SessionTimeData>> = ({
  timeData,
  chartName,
}) => {
  // const [hrSeries, setHRSeries] = useState<am5xy.LineSeries>();
  const seriesRef = useRef<am5xy.LineSeries | null>(null);

  useLayoutEffect(() => {
    // создаем основу графика
    const root = am5.Root.new(chartName);
    // root.current = root;
    // навешиваем тему
    root.setThemes([am5themes_Animated.new(root)]);

    // создаем отрисовку
    const chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    // создаем оси
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        // min: new Date(2023, 2, 23, 21, 10, 0).getTime(),
        // max: new Date(2023, 2, 23, 21, 20, 0).getTime(),
        min: timeData.minX.getTime(),
        max: timeData.maxX ? timeData.maxX.getTime() : undefined,
        strictMinMax: true,
        baseInterval: {
          timeUnit: 'millisecond',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: timeData.minY,
        max: timeData.maxY,
        renderer: am5xy.AxisRendererY.new(root, {
          // отпция застравляет отрисовывать ось Y внутри графика
          inside: true,
        }),
      }),
    );

    // создаем серию
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
      }),
    );

    seriesRef.current = series;

    // series.data.setAll([{
    //   date: new Date(2012, 1, 1).getTime(),
    //   value: 80,
    // }, {
    //   date: new Date(2012, 1, 2).getTime(),
    //   value: 50,
    // },
    // ]);

    return () => {
      root.dispose();
    };
  }, []);

  // useEffect(() => {
  //   seriesRef.current?.data.setAll([{
  //     date: new Date(2012, 1, 1).getTime(),
  //     value: 80,
  //   }, {
  //     date: new Date(2012, 1, 2).getTime(),
  //     value: 50,
  //   },
  //   ]);
  // });

  useEffect(() => {
    // стартуем обновлялку сигнала - раз в 5 секунд
    const updateData = async () => {
      try {
        console.log('Update', chartName);
        const data = await timeData.queryFn();
        const hrData = data.data as HRDataMeasure[];
        const procHR: any[] = [];
        hrData.forEach((item) => {
          procHR.push({
            date: am5.time
              .add(dayjs(item.dt).toDate(), 'millisecond', 1)
              .getTime(),
            // date: item.id,
            value: item.value,
          });
        });

        seriesRef.current?.data.setAll(procHR);

        // seriesRef.current?.data.setAll([{
        //   date: new Date(2012, 1, 1).getTime(),
        //   value: 80,
        // }, {
        //   date: new Date(2012, 1, 2).getTime(),
        //   value: 50,
        // },
        // ]);

        // console.log('hrData', hrData[0]);
      } catch (e) {
        console.error(e);
      } finally {
        // setTimeout(() => {
        //   updateData();
        // }, 1000);
      }
    };

    updateData();
  }, []);

  return <div id={chartName} style={{ width: '100%', height: '300px' }} />;
};

export default EnergyExpTimeGraph;
