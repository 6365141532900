// маркер острого стресса
export const MARKER_ACUTE_STRESS = Number(204)

enum MarkerTypeEnum {
  User = 1, // расставленные пользователем вручную
  System = 2, // системные маркеры, пришедшие от ПО
  Algorithm = 3, // маркеры расставленные алгоритмом (SPIKE, ALL)
  External = 4, // маркеры, пришедшие из-вне, например тест УЭД
}

export default MarkerTypeEnum;
