export enum LogLevel {
  debug,
  info,
  warning,
  error,
}

export interface DataType {
  dt: Date,
  level: LogLevel,
  msg: string
}

export interface LogWindowProps {
  dataSource: DataType[]
}
