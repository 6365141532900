import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../models/IUser';

const initialState: IUser = {
  id: 0,
  username: '',
  is_superuser: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<IUser>) => {
      // console.log('setUserDate');
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.is_superuser = action.payload.is_superuser;
    },
  },
});

export const { setUserData } = userSlice.actions;
export const userReducer = userSlice.reducer;
