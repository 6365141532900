import React from 'react';
import 'antd/dist/reset.css';
import { Col, Row, Layout } from 'antd';
import dayjs from 'dayjs';
import AppRouter from './components/AppRouter';
import Navbar from './components/UI/Navbar/Index';

const { Footer } = Layout;

function App() {
  return (
    <>
      <Row>
        <Col span={24}>
          <Navbar />
        </Col>
      </Row>

      <AppRouter />

      <Row>
        <Col span={24}>
          <Footer style={{ textAlign: 'center' }}>
            Cognet @ 2022 - {dayjs().format('YYYY')}
          </Footer>
        </Col>
      </Row>
    </>
  );
}

export default App;
