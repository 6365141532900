import { Menu, Layout, MenuProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/appStore';

const { Header } = Layout;

const menuNoSign: MenuProps['items'] = [
  {
    label: 'Главная',
    key: 'index',
  },
  {
    label: 'О проекте',
    key: 'about',
  },
  // {
  //   label: 'Вход',
  //   key: 'auth/login',
  // },
  // {
  //   label: 'Регистрация',
  //   key: 'auth/registration',
  // },
];

const menuSign: MenuProps['items'] = [
  {
    label: 'Монитор',
    key: 'telemetry',
  },
  // {
  //   label: 'WEB BLE Recorder',
  //   key: 'webble/recorder',
  // },
  {
    label: 'Личный кабинет',
    key: 'dicts',
  },
  {
    label: 'Выход',
    key: 'auth/logout',
  },
];

const Navbar = () => {
  const user = useAppSelector((state) => state.user);
  // console.log('Navbar', user);

  const menuItems = user.id > 0 ? menuSign : menuNoSign;
  if (user.is_superuser && menuItems.filter((item) => (item && item.key === 'administration')).length === 0) {
    menuItems.push({
      label: 'Администрирование',
      key: 'administration',
    });
  }
  const navigate = useNavigate();

  const onClick: MenuProps['onClick'] = (e) => {
    const key = e.key === 'index' ? '' : e.key;
    // console.log(key)
    navigate(`/${key}`, { replace: true });
  };

  return (
    <Header>
      <Menu mode="horizontal" theme="dark" items={menuItems} onClick={onClick} />
    </Header>
  );
};

export default Navbar;
