import React from 'react';

import { Card } from 'antd';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className="site-card-border-less-wrapper">
      <Link to="/telemetry">
        <Card title="Телеметрия" bordered={false} style={{ width: 300 }}>
          <p>Запись сердечного ритма</p>
        </Card>
      </Link>
      <Link to="/laterometer">
        <Card title="Латерометр" bordered={false} style={{ width: 300 }}>
          <p>Латерометрия</p>
        </Card>
      </Link>
      <Link to="/laterometer">
        <Card title="Тест ИнтВал " bordered={false} style={{ width: 300 }}>
          <p>Латерометрия</p>
        </Card>
      </Link>
    </div>

  );
};

export default Dashboard;
