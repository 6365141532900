import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table';
import {
  notification,
  Popconfirm, Spin, Table, Tooltip,
} from 'antd';
import {
  DeleteOutlined, EditOutlined, PlusSquareOutlined,
} from '@ant-design/icons';
import axios, { AxiosError } from 'axios';

import dayjs from 'dayjs';
import api from '../../http';
import { TrustedEmails } from '../../models/types';
import { appDateFormatWithTime } from '../../consts';
import { FormModeEnum } from '../../models/enums';
import AddEditTrustedEmailsModal from '../../components/UI/TrustedEmail/Index';

const TrustedEmailsList = () => {
  const [openAddTrustedEmailsForm, setAddOpenTrustedEmailsForm] = useState(false);
  const [openEditTrustedEmailsForm, setOpenEditTrustedEmailsForm] = useState(false);
  const [currentTrustedEmails, setCurrentTrustedEmails] = useState<TrustedEmails>();

  const queryClient = useQueryClient();
  const queryKey = ['trustedEmailsList'];

  const mutationCreatedTrustedEmails = useMutation((data: TrustedEmails) => api.v1.v1ProfileTrustedEmailsCreate(data));
  const mutationEditTrustedEmails = useMutation(
    (data: TrustedEmails) => api.v1.v1ProfileTrustedEmailsPartialUpdate(data.id!, data),
  );
  const mutationDeleteTrustedEmails = useMutation((id: number) => api.v1.v1ProfileTrustedEmailsDelete(id));

  const onCreateContext = (data: TrustedEmails) => {
    mutationCreatedTrustedEmails.mutate(
      {
        email: data.email,
        info: data.info,
      },
      {
        onError: (err) => {
          if (axios.isAxiosError(err)) {
            const errorMessage = JSON.stringify(err.response?.data);
            notification.error({ message: `Ошибка: ${errorMessage}` });
          }
        },
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          notification.success({ message: 'Новый Email был создан' });
        },
      },
    );

    setAddOpenTrustedEmailsForm(false);
  };

  const onEditContext = (data: TrustedEmails) => {
    mutationEditTrustedEmails.mutate(
      {
        id: data.id,
        email: data.email,
        info: data.info,
      },
      {
        onError: (err) => {
          if (axios.isAxiosError(err)) {
            const errorMessage = err.response?.data.detail;
            notification.error({ message: `Ошибка: ${errorMessage}` });
          }
        },
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          notification.success({ message: 'Информация была изменена' });
        },
      },
    );

    setOpenEditTrustedEmailsForm(false);
  };

  const onDeleteTrustedEmails = (record: TrustedEmails) => {
    if (record.id) {
      mutationDeleteTrustedEmails.mutate(
        record.id,
        {
          onError: (err) => {
            if (axios.isAxiosError(err)) {
              const errorMessage = err.response?.data.detail;
              notification.error({ message: `Ошибка: ${errorMessage}` });
            }
          },
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
          },
        },
      );
    }
  };

  const columns: ColumnsType<TrustedEmails> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Информация',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created',
      key: 'created',
      render: (_, record) => (
        <div>
          {dayjs(record.created).format(appDateFormatWithTime)}
        </div>
      ),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'modified',
      key: 'modified',
      render: (_, record) => (
        <div>
          {dayjs(record.modified).format(appDateFormatWithTime)}
        </div>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="Редактирование одобренного Email">
              <EditOutlined
                style={{ color: 'green', marginLeft: 12 }}
                onClick={() => {
                  setCurrentTrustedEmails(record);
                  setOpenEditTrustedEmailsForm(true);
                }}
              />
            </Tooltip>
            <Popconfirm
              title="Вы уверены, что хотите удалить ? "
              onConfirm={() => {
                onDeleteTrustedEmails(record);
              }}
              okText="Да"
              cancelText="Нет"
            >
              <Tooltip title="Удаление Email">
                <DeleteOutlined
                  style={{ color: 'red', marginLeft: 12 }}
                />
              </Tooltip>
            </Popconfirm>
          </>
        );
      },
    },

  ];

  const {
    isLoading,
    error,
    data: dataTrustedEmails,
  } = useQuery(queryKey, () => api.v1.v1ProfileTrustedEmailsList());

  if (error && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  return (
    <>
      <h1>Список одобренных Email для регистрации</h1>
      <Tooltip title="Добавление одобренного Email">
        <PlusSquareOutlined
          onClick={() => { setAddOpenTrustedEmailsForm(true); }}
          style={{ color: 'green', marginLeft: 12, fontSize: '25px' }}
        />
      </Tooltip>
      <Table columns={columns} dataSource={dataTrustedEmails?.data} bordered rowKey="id" />
      <AddEditTrustedEmailsModal
        isVisible={openAddTrustedEmailsForm}
        modeForm={FormModeEnum.Create}
        onSubmit={onCreateContext}
        onCancel={() => { setAddOpenTrustedEmailsForm(false); }}
      />
      <AddEditTrustedEmailsModal
        isVisible={openEditTrustedEmailsForm}
        modeForm={FormModeEnum.Edit}
        initData={currentTrustedEmails}
        onSubmit={onEditContext}
        onCancel={() => { setOpenEditTrustedEmailsForm(false); }}
      />
    </>
  );
};

export default TrustedEmailsList;
