// Формат данных принятый в программе

const appDateFormat = 'DD.MM.YYYY';
export const appTimeFormatWithoutSecond = 'HH:mm';
export const appTimeFormatWithSecond = 'HH:mm:ss';
export const appDateFormatWithoutSecond = 'HH:mm DD.MM.YYYY';
export const appDateFormatWithTime = 'HH:mm:ss DD.MM.YYYY';
export const apiDateFormatWithTimeMs = 'HH:mm:ss.SSS DD.MM.YYYY';

export const apiDateFormatAPITimeTZWithSecond = 'YYYY-MM-DDTHH:mm:ssZ';
export const apiDateFormatAPITimeTZWithMillisecond = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export default appDateFormat;
