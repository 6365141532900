import React, {FC} from 'react';
import {Routes, Route} from 'react-router-dom';
import About from '../pages/About';
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import Dashboard from '../pages/Dashboard';
import Recorder from '../pages/webble/Recorder';
import Dicts from '../pages/dicts/Dicts';
import Page404 from '../pages/Page404';
import Carriers from '../pages/dicts/Carriers';
import SessionContextList from '../pages/dicts/SessionContextList';
import SessionChangeMarkers from '../pages/dicts/SessionChangeMarkers';
import Telemetry from '../pages/Telemetry';
import Session from '../pages/telemetry/sessions/Session';
import Laterometer from '../pages/Laterometer';
import ValenceInterference from '../pages/usertest/valenceinterference/ValenceInterference';
import Program from '../pages/Program';
import RegistrationAccount from '../pages/auth/RegistrationAccount';
import Account from '../pages/Account';
import VerifyToken from '../pages/auth/VerifyToken';
import Administration from '../pages/Administration';
import TrustedEmailsList from '../pages/administration/TrustedEmailsList';
import CarrierSensorsList from '../pages/administration/CarrierSensorsList';
import SessionReport from '../pages/telemetry/sessions/SessionReport';
import SensorList from "../pages/dicts/SensorList";
import LogInformationList from "../pages/administration/LogInformationList";
import PasswordReset from "../pages/auth/PasswordReset";
import NewPassword from "../pages/auth/NewPassword";
import ExpertList from "../pages/administration/ExpertList";

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Login/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/program" element={<Program/>}/>
      <Route path="/account" element={<Account/>}/>
      <Route path="/sensor_list" element={<SensorList/>}/>

      <Route path="/auth/login" element={<Login/>}/>
      <Route path="/auth/logout" element={<Logout/>}/>
      <Route path="/auth/registration" element={<RegistrationAccount/>}/>
      <Route path="/auth/verify_token" element={<VerifyToken/>}/>
      <Route path="/auth/password_reset" element={<PasswordReset/>}/>
      <Route path="/auth/new_password/:token" element={<NewPassword/>}/>

      <Route path="/dashboard" element={<Dashboard/>}/>
      <Route path="/webble/recorder" element={<Recorder/>}/>

      <Route path="/telemetry" element={<Telemetry/>}/>
      <Route path="/telemetry/sessions/:sessionId" element={<Session/>}/>
      <Route path="/telemetry/sessions_report/:sessionId/:filtered" element={<SessionReport/>}/>

      <Route path="/dicts" element={<Dicts/>}/>

      <Route path="/dicts/carriers" element={<Carriers/>}/>
      {/* <Route path="/dicts/users/:userId" element={<User />} /> */}
      {/* <Route path="/dicts/users/add" element={<User />} /> */}

      <Route path="/dicts/contexts" element={<SessionContextList/>}/>
      <Route path="/dicts/contexts/:contextId" element={<SessionChangeMarkers/>}/>

      <Route path="/laterometer" element={<Laterometer/>}/>

      <Route path="/usertest/valenceinterference" element={<ValenceInterference/>}/>

      <Route path="/administration/carrier_sensors" element={<CarrierSensorsList/>}/>
      <Route path="/administration/approved_users" element={<TrustedEmailsList/>}/>
      <Route path="/administration/log_information" element={<LogInformationList/>}/>
      <Route path="/administration/experts" element={<ExpertList/>}/>
      <Route path="/administration" element={<Administration/>}/>

      <Route path="*" element={<Page404/>}/>

    </Routes>
  );
};

export default AppRouter;
