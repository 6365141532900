import React from "react";
import {Button, Col, Form, Input, notification, Row, Spin, Typography} from "antd";
import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {apiWithoutInterceptor} from "../../http";

const {Title} = Typography;

const PasswordReset = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const mutationSendResetEmail = useMutation({
    mutationFn: (email: string) => apiWithoutInterceptor.passwordReset.passwordResetCreate({email}),
    onSuccess: () => {
      notification.info({
        message: 'На почту было выслано письмо с кодом активации',
      });
      navigate("/", {replace: true});
    },
    onError: () => notification.error({message: "Ошибка системы или пользователь не существует"})
  })

  return (
    <Row>
      <Col span={12} offset={6}>
        {mutationSendResetEmail.isLoading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>
        )}
        <Title level={3} style={{textAlign: 'center'}}>
          Смена пароля учетной записи:
        </Title>
        <Form
          onFinish={() => {
            mutationSendResetEmail.mutate(
              form.getFieldValue('email'),
            );
          }}
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          form={form}
        >
          <Form.Item
            label="Ваш email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Введите свой Email',
                type: 'email',
              },
            ]}
          >
            <Input/>
          </Form.Item>
          <Form.Item wrapperCol={{offset: 8, span: 16}} name="submit">
            <Button type="primary" htmlType="submit">
              Сменить пароль
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
};

export default PasswordReset;