import React, { useState } from 'react';
import DebounceSelect from '../DebounceSelect/Index';
import { ContextSelectProps, ContextSelectValue } from './types';
import api from '../../../http';

const SelectContex: React.FC<ContextSelectProps> = ({ context, setContext }) => {
  const fetchContextList = async (name: string): Promise<ContextSelectValue[]> => {
    // TODO Разобраться с name
    const response = await api.v1.v1ContextsList();

    const contextSelect: ContextSelectValue[] = [];

    for (let i = 0; i < response.data.length; i += 1) {
      const contextId = response.data[i].id;
      if (contextId) {
        contextSelect.push({ value: contextId, label: response.data[i].name });
      }
    }

    return contextSelect;
  };

  return (
    <DebounceSelect
      value={context}
      allowClear
      placeholder="Выберите контекст"
      fetchOptions={fetchContextList}
      onChange={(newValue) => {
        setContext(newValue as ContextSelectValue);
      }}
    />
  );
};

export default SelectContex;
