import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../models/IUser';
import { setUserData } from '../../redux/slices/user';
import { useAppDispatch } from '../../hooks/appStore';
import { clearTokens } from '../../utils/localStorage';

const Logout = () => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    // удалим информацию из локального хранилища
    clearTokens();

    // сбросим пользователя на анонимуса
    const user: IUser = {
      id: 0,
      username: '',
      is_superuser: false,
    };
    appDispatch(setUserData(user));

    navigate('/', { replace: true });
  }, []);

  return (
    <div>Logout page</div>
  );
};

export default Logout;
