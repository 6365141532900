export const setTokens = ({ access, refresh }: {
  access: string;
  refresh: string;
}) => {
  localStorage.setItem('token-access', access);
  localStorage.setItem('token-refresh', refresh);
};

export const getLocalAccessToken = () => {
  return localStorage.getItem('token-access');
};

export const getLocalRefreshToken = () => {
  return localStorage.getItem('token-refresh');
};

export const clearTokens = () => {
  localStorage.removeItem('token-access');
  localStorage.removeItem('token-refresh');
};
