enum MeasureCommandEnum {
  StartSession = 'START_SESSION',
  FinishSession = 'FINISH_SESSION',
  RRDataMeasure = 'RR_DATA_MEASURE',
}

export enum FormModeEnum {
  Create = 1,
  Edit,
}

export default MeasureCommandEnum;
