import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  Button,
  notification, Popconfirm, Space, Spin, Table, Tooltip,
} from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../../models/types';
import api from '../../http';
import { AddEditContextModal } from '../../components/UI/Context/Index';
import { FormModeEnum } from '../../models/enums';

const SessionContextList = () => {
  const [openAddContextForm, setOpenAddContextForm] = useState(false);
  const [openEditContextForm, setOpenEditContextForm] = useState(false);
  const [currentContext, setCurrentContext] = useState<SessionContext>();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutationCreateContext = useMutation((data: SessionContext) => api.v1.v1ContextsCreate(data));
  const mutationEditContext = useMutation(
    (data: SessionContext) => api.v1.v1ContextsPartialUpdate(data.id!.toString(), data),
  );
  const mutationDeleteContext = useMutation((id: number) => api.v1.v1ContextsDelete(id.toString()));

  const {
    isLoading,
    error,
    data: dataContext,
  } = useQuery(['contextsList'], () => api.v1.v1ContextsList());

  const onCreateContext = (data: SessionContext) => {
    mutationCreateContext.mutate(
      { name: data.name },
      {
        onError: (err) => {
          if (axios.isAxiosError(err)) {
            const errorMessage = err.response?.data.detail;
            notification.error({ message: `Ошибка: ${errorMessage}` });
          }
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['contextsList']);
          notification.success({ message: 'Новый контекст был создан' });
        },
      },
    );

    setOpenAddContextForm(false);
  };

  const onEditContext = (data: SessionContext) => {
    mutationEditContext.mutate(
      data,
      {
        onError: (err) => {
          if (axios.isAxiosError(err)) {
            const errorMessage = err.response?.data.detail;
            notification.error({ message: `Ошибка: ${errorMessage}` });
          }
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['contextsList']);
          notification.success({ message: 'Контекст был отредактирован' });
        },
      },
    );

    setOpenEditContextForm(false);
  };

  const onDeleteContext = (record: SessionContext) => {
    if (record.id) {
      mutationDeleteContext.mutate(
        record.id,
        {
          onError: (err) => {
            if (axios.isAxiosError(err)) {
              const errorMessage = err.response?.data.detail;
              notification.error({ message: `Ошибка: ${errorMessage}` });
            }
          },
          onSuccess: () => {
            queryClient.invalidateQueries(['contextsList']);
          },
        },
      );
    }
  };

  const columns: ColumnsType<SessionContext> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space wrap>
            <Button
              style={{ borderColor: 'green', color: 'green' }}
              shape="round"
              onClick={() => {
                setCurrentContext(record);
                setOpenEditContextForm(true);
              }}
            >Изменить название
            </Button>
            <Button
              shape="round"
              style={{ borderColor: 'blue', color: 'blue' }}
              onClick={() => {
                navigate(`/dicts/contexts/${record.id}`);
              }}
            >События
            </Button>
            <Popconfirm
              title="Вы уверены, что хотите удалить контекст? "
              onConfirm={() => {
                onDeleteContext(record);
              }}
              okText="Да"
              cancelText="Нет"
            >
              <Button shape="round" danger>
                Удалить
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },

  ];

  if (error && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  return (
    <>
      <h1>Список контекстов</h1>
      <Tooltip title="Добавление контекста">
        <Button shape="round" onClick={() => { setOpenAddContextForm(true); }}>
          Добавление контекста
        </Button>
      </Tooltip>

      <Table columns={columns} dataSource={dataContext?.data} bordered rowKey="id" pagination={false} />
      <AddEditContextModal
        isVisible={openAddContextForm}
        modeForm={FormModeEnum.Create}
        onSubmit={onCreateContext}
        onCancel={() => { setOpenAddContextForm(false); }}
      />
      <AddEditContextModal
        isVisible={openEditContextForm}
        modeForm={FormModeEnum.Edit}
        initData={currentContext}
        onSubmit={onEditContext}
        onCancel={() => { setOpenEditContextForm(false); }}
      />
    </>
  );
};

export default SessionContextList;
