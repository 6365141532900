import {Button, Col, Popconfirm, Row, Space, Spin, Table, TableProps, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import dayjs from "dayjs";
import {DeleteTwoTone} from "@ant-design/icons";
import {LogInformation, SessionFrontend} from "../../models/types";
import api from "../../http";
import {appDateFormatWithTime} from "../../consts";
import {TableParams} from "../../models/ITable";
import {getFirstValue} from "../../models/helper";

const LogInformationList = () => {

  const DEFAULT_PAGE_SIZE = 15

  const queryClient = useQueryClient();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    sorter: []
  });
  const mutationLogInformationDelete = useMutation(
    (record_id: number) => api.v1.v1LogInformationDelete(record_id));

  const getQueryKey = () => {
    return ['LogInformationList', tableParams]
  };

  const onDeleteLogInformation = (record: LogInformation) => {
    if (record.id) {
      mutationLogInformationDelete.mutate(
        record.id,
        {
          onSuccess: () => {
            queryClient.invalidateQueries(getQueryKey());
          }
        }
      )
    }
  }

  const columns: ColumnsType<LogInformation> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true,
    },
    {
      title: 'Дата',
      dataIndex: 'created',
      key: 'created',
      width: 150,
      sorter: true,
      render: (_, record) => (
        <div>
          {dayjs(record.created).format(appDateFormatWithTime)}
        </div>
      ),
    },
    {
      title: 'Модуль',
      dataIndex: 'module',
      key: 'module',
      width: 200,
    },
    {
      title: 'Уровень',
      dataIndex: 'level',
      key: 'level',
      width: 50,
      sorter: true,
    },
    {
      title: 'Пользователь',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 150,
    },
    {
      title: 'Сообщение',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      width: 20,
      render: (_, record) => (
        <div>
          <Tooltip title="Удаление сообщения">
            <Popconfirm
              title="Вы уверены, что хотите удалить сообщение?"
              onConfirm={() => {
                onDeleteLogInformation(record);
              }}
              okText="Да"
              cancelText="Нет"
            >
              <DeleteTwoTone twoToneColor="red"/>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ]

  const handleTableChange: TableProps['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    })
  };

  useEffect(() => {
    console.log("2", JSON.stringify(tableParams));
  }, [JSON.stringify(tableParams)]);

  const {
    isLoading,
    error,
    data: dataLogInformation,
  } = useQuery(getQueryKey(), () => {

    // получим поля для сортировки
    const firstSorter = getFirstValue(tableParams.sorter)
    const order = firstSorter?.order;
    let field: string | undefined;
    if (typeof firstSorter?.field === 'string') {
      field = firstSorter.field
    }

    let apiOrderField: string | undefined;
    if (order && field) {
      apiOrderField = `${order === "ascend" ? "" : "-"}${field}`
    }

    const limit = tableParams.pagination?.pageSize;
    let offset: number | undefined;
    if (tableParams.pagination?.current !== undefined && tableParams.pagination?.current !== null && tableParams.pagination?.pageSize !== undefined) {
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      offset = (tableParams.pagination?.current - 1) * tableParams.pagination?.pageSize
    }

    return api.v1.v1LogInformationList(
      {
        order: apiOrderField as 'id' | '-id' | 'created' | '-created' | 'level' | '-level',
        limit,
        offset,
      });
  });

  if (error && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}><Spin/></div>;
  }

  return (
    <Row>
      <Col span={24} style={{padding: 20}}>
        <h1>Информационные сообщения</h1>
        <Table
          columns={columns}
          dataSource={dataLogInformation?.data.results}
          bordered
          rowKey="id"
          onChange={handleTableChange}
          pagination={{
            current: tableParams.pagination?.current,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            position: ["bottomCenter"],
            total: dataLogInformation?.data.count,
            showTotal: (total) => `Записей: ${total}`,
          }}
        />
      </Col>
    </Row>
  );
}

export default LogInformationList;