import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import dayjs from 'dayjs';
import { SessionTimeData, SessionTimeGraphProps } from './types';
import { HRDataMeasure } from '../../../models/types';

const HRTimeGraph: React.FC<SessionTimeGraphProps<SessionTimeData>> = ({
  timeData,
  chartName,
  graphRangeX,
  setGraphRangeX,
}) => {
  let cursorSelectRangeStartX: Date = new Date();

  // const [hrSeries, setHRSeries] = useState<am5xy.LineSeries>();
  const seriesRef = useRef<am5xy.LineSeries | null>(null);
  const axisXRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer> | null>(null);

  const [hrCount, setHRCount]= useState<number>();

  useLayoutEffect(() => {
    // создаем основу графика
    const root = am5.Root.new(chartName);

    // прячем лого
    root._logo?.dispose();

    // навешиваем тему
    root.setThemes([am5themes_Animated.new(root)]);

    // создаем отрисовку
    const chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    // создадим рамку на графике
    const background = chart.plotContainer.get("background");
    if (background){
      background.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
        stroke: am5.color('#000'),
        fill: am5.color(0xffffd1),
        fillOpacity: 1.0,
      })
    }

    // отключим кнопку ZOOM
    chart.zoomOutButton.set("forceHidden", true);

    // создадим курсор
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'selectX',
      }),
    );

    // создаем оси
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        // min: new Date(2023, 2, 23, 21, 10, 0).getTime(),
        // max: new Date(2023, 2, 23, 21, 20, 0).getTime(),
        min: timeData.minX.getTime(),
        max: timeData.maxX ? timeData.maxX.getTime() : undefined,
        strictMinMax: true,
        baseInterval: {
          timeUnit: 'millisecond',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );
    axisXRef.current = xAxis;

    // обрабатываем начало выделения
    cursor.events.on('selectstarted', () => {
      const posX = cursor.getPrivate('positionX');
      if (posX) {
        cursorSelectRangeStartX = xAxis.positionToDate(
          xAxis.toAxisPosition(posX),
        );
      }
    });
    // обрабатываем конец выделения
    cursor.events.on('selectended', () => {
      const posX = cursor.getPrivate('positionX');
      if (posX) {
        const cursorSelectRangeEndX = xAxis.positionToDate(
          xAxis.toAxisPosition(posX),
        );
        timeData.onCreateEventManual({
          event_start: cursorSelectRangeStartX,
          event_end: cursorSelectRangeEndX,
        });
      }
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: timeData.minY,
        max: timeData.maxY,
        renderer: am5xy.AxisRendererY.new(root, {
          // отпция застравляет отрисовывать ось Y внутри графика
          inside: true,
        }),
        // фиксим ошибку, что внутри метки не видны при полной непрозрачности
        layer:1,
      }),
    );

    yAxis.get('renderer').labels.template.setAll({
      dy:10,
    });

    // создаем серию
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0x000000),
      }),
    );

    seriesRef.current = series;

    // series.data.setAll([{
    //   date: new Date(2012, 1, 1).getTime(),
    //   value: 80,
    // }, {
    //   date: new Date(2012, 1, 2).getTime(),
    //   value: 50,
    // },
    // ]);

    return () => {
      root.dispose();
    };
  }, []);

  // useEffect(() => {
  //   seriesRef.current?.data.setAll([{
  //     date: new Date(2012, 1, 1).getTime(),
  //     value: 80,
  //   }, {
  //     date: new Date(2012, 1, 2).getTime(),
  //     value: 50,
  //   },
  //   ]);
  // });

  useEffect(() => {
    // стартуем обновлялку сигнала - раз в 5 секунд
    const updateData = async () => {
      try {
        console.log('Update', chartName);
        const data = await timeData.queryFn();
        const hrData = data.data as HRDataMeasure[];
        const procHR: any[] = [];
        hrData.forEach((item) => {
          procHR.push({
            date: am5.time
              .add(dayjs(item.dt).toDate(), 'millisecond', 1)
              .getTime(),
            // date: item.id,
            value: item.value,
          });
        });

        seriesRef.current?.data.setAll(procHR);
        setHRCount(procHR.length);

        // seriesRef.current?.data.setAll([{
        //   date: new Date(2012, 1, 1).getTime(),
        //   value: 80,
        // }, {
        //   date: new Date(2012, 1, 2).getTime(),
        //   value: 50,
        // },
        // ]);

        // console.log('hrData', hrData[0]);
      } catch (e) {
        console.error(e);
      } finally {
        // setTimeout(() => {
        //   updateData();
        // }, 1000);
      }
    };

    updateData();
  }, []);

  useEffect(() => {
    if (!setGraphRangeX) {
      if (graphRangeX) {
        axisXRef.current?.setAll(graphRangeX);
      }
    }
  }, [graphRangeX]);

  // console.log(graphRangeX);

  return (
    <>
      <div>Name: <b>HR</b>, points: <b>{hrCount}</b></div>
      <div id={chartName} style={{ width: '100%', height: '250px' }} />
    </>
 )
};

export default HRTimeGraph;
