import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { notification, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CheckCircleTwoTone, DownloadOutlined } from '@ant-design/icons';
import axios, { AxiosError } from 'axios';
import api from '../http';
import { ProgramVersion } from '../models/types';

const Program = () => {
  const useProgramsData = () => {
    const {
      isLoading: programsVersionIsLoading,
      error: programsVersionError,
      data: programsVersionData,
    } = useQuery(['programsVersion'], () => api.v1.v1ProgramsVersionList());
    const {
      isLoading: programsSectionIsLoading,
      error: programsSectionError,
      data: programsSectionData,
    } = useQuery(
      ['programsSection'],
      () => api.v1.v1ProgramsSectionList(),
      {
        enabled: !!programsVersionData,
      },
    );

    const isError = programsVersionError || programsSectionError;
    const isLoading = programsVersionIsLoading || programsSectionIsLoading;

    return {
      isError,
      isLoading,
      programsSectionData,
      programsVersionData,
    };
  };

  const {
    isError,
    isLoading,
    programsVersionData,
    programsSectionData,
  } = useProgramsData();

  if (isError && axios.isAxiosError(isError)) {
    const err: AxiosError = isError as AxiosError;
    console.log('isError', err.message);
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  const section = (
    programsSectionData && programsSectionData.data && Object.fromEntries(programsSectionData?.data.map(
      (x) => [x.id, x.name],
    ))
  ) || {};

  const columns: ColumnsType<ProgramVersion> = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Раздел',
      dataIndex: 'section',
      key: 'section',
      render: (_, record) => (
        section[record.section] || ''
      ),
    },
    {
      title: 'Файл',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Версия',
      dataIndex: 'version',
      key: 'version',
      render: (_, record) => (
        <div>
          {record.version}
          &nbsp;
          {record.actual ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : ''}
        </div>
      ),
    },
    {
      title: 'Изменения',
      dataIndex: 'changelog',
      key: 'changelog',
    },
    {
      title: 'Скачать',
      dataIndex: 'url',
      key: 'url',
      render: (_, record) => (
        <DownloadOutlined onClick={async (e) => {
          if (record.id) {
            try {
              const response = await api.v1.v1ProgramsVersionDownloadFileRead(
                record.id?.toString(),
                {
                  format: 'blob',
                },
              );
              const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
              if (url) {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', record.filename);
                link.click();
                window.URL.revokeObjectURL(url);
              }
            } catch (err) {
              notification.error({ message: 'Проблемы со скачиванием файла' });
            }
            e.preventDefault();
          }
        }}
        />
      ),
    },
  ];

  return (
    <>
      <h1>Программы</h1>
      <Table columns={columns} dataSource={programsVersionData?.data} bordered rowKey="id" pagination={false} />
    </>
  );
};

export default Program;
