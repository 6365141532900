import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { RealTimeGraphItem, RealTimeGraphProps } from './types';

const RealTimeGraph: React.FC<RealTimeGraphProps> = ({ item, setItem }) => {
  const data:RealTimeGraphItem[] = [];

  const [series, setSeries] = useState<am5xy.LineSeries | null>(null);

  useLayoutEffect(() => {
    // создаем основу графика
    const root = am5.Root.new('chartdiv');
    // root.current = root;
    // навешиваем тему
    root.setThemes([am5themes_Animated.new(root)]);

    // создаем отрисовку
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
      }),
    );

    // создаем оси
    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {
        timeUnit: 'second',
        count: 1,
      },
      renderer: am5xy.AxisRendererX.new(root, {}),
    }));
    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 200,
      max: 1500,
      renderer: am5xy.AxisRendererY.new(root, {}),
    }));

    setSeries(chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
      }),
    ));

    series?.data.setAll(data);

    // // Добавим курсор
    // const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
    //   xAxis,
    // }));
    // cursor.lineY.set('visible', false);

    // function addData() {
    //   const lastDataItem = series.dataItems[series.dataItems.length - 1];
    //
    //   const lastValueX = lastDataItem.get('valueX');
    //   const lastValueY = lastDataItem.get('valueY');
    //
    //   const newValueY = value + ((Math.random() < 0.5 ? 1 : -1) * Math.random() * 5);
    //
    //   console.log('lastValueX', lastValueX);
    //   console.log('lastDateY', lastValueY);
    //
    //   if (lastValueX) {
    //     const lastDate = new Date(lastValueX);
    //
    //     const time = am5.time.add(new Date(lastDate), 'second', 1).getTime();
    //     series.data.removeIndex(0);
    //     series.data.push({
    //       date: time,
    //       value: newValueY,
    //     });
    //   }
    // }

    // // Update data every second
    // setInterval(() => {
    //   addData();
    // }, 1000);

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    if (item && series) {
      const time = am5.time.add(item.dt, 'millisecond', 1).getTime();

      if (series.data.length > 120) {
        series.data.removeIndex(0);
      }

      series.data.push({
        date: time,
        value: item.value,
      });
    }

    // console.log(item);
    // console.log(series?.data);
  }, [item]);

  return (
    <div id="chartdiv" style={{ width: '100%', height: '400px' }} />
  );
};

export default RealTimeGraph;
