import Dexie, { Table } from 'dexie';
import MeasureCommandEnum from '../models/enums';

// идея такая, будем хранить цепочку комманд ( начало сессии - передача RR данных )
export interface ChainCommands {
  id?: number;
  dt: Date;
  uuid: string; // комманды одной сессии прошиты одним идентификатором
  command: MeasureCommandEnum;
  payload: string; // json формат нагрузки
}

export class DataMeasureDexie extends Dexie {
  chainCommands!: Table<ChainCommands>;

  constructor() {
    super('measure');
    this.version(1).stores({
      chainCommands: '++id, dt, uuid', // Primary key and indexed props
    });
  }
}

export const db = new DataMeasureDexie();
