import React from 'react';
import { List, Typography } from 'antd';
import dayjs from 'dayjs';
import { LogLevel, LogWindowProps } from './types';
import { appDateFormatWithTime } from '../../../consts';

const { Text } = Typography;

const msgColor = (level: LogLevel) => {
  switch (level) {
    case LogLevel.info:
      return '#2f6b2f';
    case LogLevel.warning:
      return '#0b38ea';
    case LogLevel.error:
      return '#FF0000';
    default:
      return '#000000';
  }
};

const LogWindow: React.FC<LogWindowProps> = ({ dataSource }) => (
  <List
    dataSource={dataSource}
    itemLayout="horizontal"
    bordered
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          description={(
            <Text>
              <strong>{dayjs(item.dt).format(appDateFormatWithTime)}</strong>
              &nbsp;
              <span style={{ color: msgColor(item.level) }}>{item.msg}</span>
            </Text>
          )}
        />
      </List.Item>
    )}
  />
);

export default LogWindow;
