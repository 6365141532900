import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import api from '../../../http';
import { SelectMarkerProps } from './types';

// https://atlassc.net/2021/06/05/create-a-custom-ant-design-form-item-component

const SelectMarker = ({ value, onChange }: SelectMarkerProps) => {
  const {
    isError,
    isLoading,
    data: dataMarkers,
  } = useQuery(['marker_list'], () => api.v1.v1MarkersList({ event_type: ['1'] }));

  const handleChangeValue = (newValue: number) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  return (
    <Select
      showSearch
      status={isError ? 'error' : ''}
      loading={isLoading}
      filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
      placeholder="Выберите событие"
      options={dataMarkers?.data}
      fieldNames={{ label: 'name', value: 'id' }}
      onChange={(val) => handleChangeValue(val)}
      value={value}
    />
  );
};

export default SelectMarker;
