import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Modal, Popconfirm, Table } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Space } from 'antd/lib';
import ProtocolSessionDialogProps from './types';
import api from '../../../http';
import {
  appTimeFormatWithSecond,
} from '../../../consts';
import {MarkerItem} from "../../UI/SessionTimeGraph/types";

const ProtocolSessionDialog = ({
  isShow,
  queryKeyInvalidate,
  onSubmit,
  markerItems
}: ProtocolSessionDialogProps) => {

  const queryClient = useQueryClient();

  const columns: ColumnsType<MarkerItem> = [
    {
      title: 'Начало',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, record) =>
        dayjs(record.start_dt).format(appTimeFormatWithSecond),
    },
    {
      title: 'Конец',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, record) =>
        (record.end_dt
          ? dayjs(record.end_dt).format(appTimeFormatWithSecond)
          : ''),
    },
    {
      title: 'Название',
      dataIndex: 'marker_name',
      key: 'marker_name',
      render: (_, record) => record.name,
    },
    {
      title: 'Дополнительно',
      dataIndex: 'notes',
      key: 'notes',
      render: (_, record) => {
        try {
          return record.notes?.split('\n').map((item) => <p>{item}</p>)
        }
        catch (e) {
          return record.notes
        }
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Popconfirm
              title="Перерасчет займет некоторое время"
              onConfirm={() => {
                if (record.id) {
                  api.v1.v1EventsRecalculateRead(record.id.toString());
                }
              }}
              okText="Да"
              cancelText="Нет"
            >
                <Button>
                  Пересчитать
                </Button>
            </Popconfirm>
            <Popconfirm
              title="Вы уверены, что хотите удалить событие?"
              onConfirm={() => {
                if (record.id) {
                  api.v1.v1EventsDeleteRead(record.id.toString()).then(() => {
                    queryClient.invalidateQueries(queryKeyInvalidate);
                  });
                }
              }}
              okText="Да"
              cancelText="Нет"
            >
              <Button danger>
                Удалить
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      const handleResize = () => setWindowDimensions(getWindowDimensions());

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  return (
    <Modal
      title="Список событий"
      open={isShow}
      onOk={onSubmit}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      style={{ width: (70 * width) / 100, minWidth: (70 * width) / 100 }}
    >

      <Table
        columns={columns}
        dataSource={markerItems}
        bordered
        rowKey="id"
      />
    </Modal>
  );
};

export default ProtocolSessionDialog;
