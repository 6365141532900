import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  Button, Space, Spin, Table,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Carrier } from '../../models/types';
import useFetching from '../../hooks/useFetching';
import { getLocalAccessToken } from '../../utils/localStorage';
import RedirectLogin from '../../components/UI/RedirectLogin/Index';
import api from '../../http';

const Carriers = () => {
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const navigate = useNavigate();

  const [fetchCarriers, isCarriersLoading, carriersError] = useFetching(async () => {
    const response = await api.v1.v1CarriersListList();
    setCarriers(response.data);
  });

  const columns: ColumnsType<Carrier> = [
    {
      title: 'Ник',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Дата рождения',
      dataIndex: 'date_birth',
      key: 'date_birth',
    },
    {
      title: 'Пол',
      dataIndex: 'gender',
      key: 'gender',
      render: (_, record) => (
        <div>
          {record.gender === 1 ? 'М' : 'Ж' }
        </div>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {/* <Link to={`/dicts/users/${record.id}`} key={record.id}>Редактировать</Link> */}
          {/* <Link to="/">Delete</Link> */}
        </Space>
      ),
    },

  ];

  useEffect(() => {
    fetchCarriers();
  }, []);

  if (!getLocalAccessToken()) {
    return <RedirectLogin />;
  }

  return (
    <>
      <h1>Список испытуемых</h1>
      {/*      <Button
        type="primary"
        onClick={() => { navigate('/dicts/users/add'); }}
      >
        Добавить
      </Button> */}
      {carriersError && <h2>Произошла ошибка: {carriersError}</h2>}

      { isCarriersLoading && <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>}

      <Table columns={columns} dataSource={carriers} bordered rowKey="id" />
    </>
  );
};

export default Carriers;
