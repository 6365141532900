import React from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { notification, Spin } from 'antd';
import { apiWithoutInterceptor } from '../../http';

const VerifyToken = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');
  const id = searchParams.get('id');

  console.log(token);

  if (!token) {
    notification.error({ message: 'Отсутствует идентификатор' });
    return <Navigate to="/auth/login" replace />;
  }
  if (!id) {
    notification.error({ message: 'Отсутствует номер идентификатора' });
    return <Navigate to="/auth/login" replace />;
  }

  const {
    isLoading,
    error: isError,
    data,
  } = useQuery(
    ['verify_account'],
    () => apiWithoutInterceptor.v1.v1ProfileTokensActivate(+id, { token }),
  );

  if (isError) {
    return <>Произошла ошибка: ${isError}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  notification.info({ message: data?.data.detail });

  return <Navigate to="/auth/login" replace />;
};

export default VerifyToken;
