import React from 'react';
import { Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';

const Administration = () => {
  const data = [
    {
      title: 'Эксперты',
      key: '/administration/experts',
    },
    {
      title: 'Пользователи с доступом к системе',
      key: '/administration/approved_users',
    },
    {
      title: 'Список носителей и сенсоров',
      key: '/administration/carrier_sensors',
    },
    {
      title: 'Информационные сообщения',
      key: '/administration/log_information',
    },
  ];

  return (
    <Row>
      <Col span={12} offset={6}>
        <h1>Администрирование системы</h1>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={<Link to={item.key}>{item.title}</Link>} />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default Administration;
