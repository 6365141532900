import React, {
  Fragment, useEffect, useLayoutEffect, useState,
} from 'react';
import {
  Col, Row, Form,
  Button, notification,
} from 'antd';
import dayjs from 'dayjs';
import parseHeartRateCharacteristic from '../../webble/helper';
import SelectCarrier from '../../components/UI/SelectCarrier/Index';
import SelectContex from '../../components/UI/SelectContext/Index';
import LogWindow from '../../components/UI/LogWindow/Index';
import { DataType as LogDataType, LogLevel as LogDataLevel } from '../../components/UI/LogWindow/types';
import { CarrierSelectValue } from '../../components/UI/SelectCarrier/types';
import { ContextSelectValue } from '../../components/UI/SelectContext/types';
import MeasureCommandEnum from '../../models/enums';
import { db } from '../../utils/db';
import { SessionCreate } from '../../models/types';
import { apiDateFormatWithTimeMs } from '../../consts';
import RealTimeGraph from '../../components/UI/RealTimeGraph/Index';
import { RealTimeGraphItem } from '../../components/UI/RealTimeGraph/types';

// инструкция по настройке bluetooth устройства в Linux https://wiki.archlinux.org/title/bluetooth
// мое устройство Product: CSR8510 A10
// systemctl restart bluetooth
// bluetoothctl
// scan on
// agent on
// devices
// trust 78:A5:04:81:A6:2D     - моя зефирка
// connect  78:A5:04:81:A6:2D

// Полезные ссылки https://stackoverflow.com/questions/54789954/efficient-storage-and-retrieval-of-time-series-data-with-indexeddb
// https://habr.com/ru/post/428980/ - про брокер

// работают только, если вынести из класса - ???????
let bluetoothDevice: BluetoothDevice | null = null;
// мы должны разделять параметры для устройства и рендринга - иначе они не особо дружат друг с другом
let hrCharacteristic: BluetoothRemoteGATTCharacteristic | undefined;
// статус запуска измерения
let isMeasureStatus = false;

const Recorder = () => {
  const [supportsBluetooth, setSupportsBluetooth] = useState(false);
  const [logData, setLogData] = useState<LogDataType[]>([]);
  const [carrier, setCarrier] = useState<CarrierSelectValue>({ value: 0, label: '' });
  const [context, setContext] = useState<ContextSelectValue>({ value: 0, label: '' });
  const [uuid, setUuid] = useState<string>('');
  const [realTimeGraphItem, setRealTimeGraphItem] = useState<RealTimeGraphItem | null>(null);
  const [heartRate, setHeartRate] = useState<number>(0);
  const [resetTime, setResetTime] = useState(true);
  const [isMeasure, setIsMeasure] = useState(false);

  let dateMeasureRR = dayjs();
  const timeoutReconnectSecond = 10;

  useEffect(() => {
    if (navigator.bluetooth) {
      setSupportsBluetooth(true);
    }

    // // Update data every second
    // setInterval(() => {
    //   const item:RealTimeGraphItem = { dt: new Date(), value: Math.random() * 1000 + 200 };
    //   setRealTimeGraphItem(item);
    // }, 1000);
  }, []);

  const addLogMessage = (msg: string, logLevel = LogDataLevel.debug) => {
    setLogData([...logData, { dt: new Date(), level: logLevel, msg }]);
  };

  const setStatusMeasure = (status: boolean) => {
    // приходится разделять переменные обозначающее одно и тоже
    setIsMeasure(status);
    isMeasureStatus = status;
  };

  async function addMeasureData(dt: Date, uuidSession: string, command: MeasureCommandEnum, payload: string) {
    try {
      await db.chainCommands.add({
        dt, uuid: uuidSession, command, payload,
      });
    } catch (error) {
      console.log('dixie', error);
    }
  }

  const processRRInterval = (event: Event) => {
    // console.log('isStartMeasure', isStartMeasureStatus, bluetoothDevice);
    if (event.target) {
      // console.log('device new3', bluetoothDevice);
      const target = event.target as BluetoothRemoteGATTCharacteristic;
      // console.log('Received heart rate measurement', target.value);
      if (target.value) {
        // пришли RR интервалы
        const [hr, rrIntervals] = parseHeartRateCharacteristic(target.value);
        // у нас 2 ситуации
        // первая RR пришли впервые или после реконнекта датчика
        if (resetTime) {
          // зафиксируем время прихода
          dateMeasureRR = dayjs();
          // console.log('dateMeasureRR1', dateMeasureRR.format(apiDateFormatWithTimeMs));
          // придти могут сразу несколько интервалов
          const sumRR = rrIntervals.reduce((accumulator, current) => {
            return accumulator + current;
          }, 0);
          // console.log('sumRR', sumRR);
          // событие уже произошло, немного откатываемся назад
          dateMeasureRR.subtract(sumRR, 'milliseconds');
          // console.log('dateMeasureRR2', dateMeasureRR.format(apiDateFormatWithTimeMs));
          // теперь мы пологаемся на точность датчика
          setResetTime(false);
        }

        rrIntervals.forEach((rr) => {
          dateMeasureRR.add(rr, 'milliseconds');
          console.log('dateMeasureRR', dateMeasureRR.format(apiDateFormatWithTimeMs), 'RR', rr, 'current', dayjs().format(apiDateFormatWithTimeMs));
          // console.log('dateRR', dateRR, 'rr', rr);
          const item:RealTimeGraphItem = {
            dt: dateMeasureRR.toDate(),
            value: rr,
          };
          setRealTimeGraphItem(item);
        });

        // выставим значение пульса
        setHeartRate(hr);
        addLogMessage(`RR интервалы ${rrIntervals}`);
      }
    }
  };

  const connectDevice = async () => {
    try {
      console.log('connectDevice: Cоединяемся с устройством');
      // при каждом новом соединении - переинициализируем время
      setResetTime(true);
      // ШАГ 2: Попробуем соединится с выбранным устройством
      console.log('connectDevice: получаем GATT сервер');
      const gattServer = await bluetoothDevice?.gatt?.connect();
      addLogMessage(`Соединение с устройством: ${bluetoothDevice?.name}`);

      console.log('connectDevice:', bluetoothDevice);

      /*      // тут у нас батарейка
    if (gattServer) {
      const serviceBattery = await gattServer.getPrimaryService('battery_service');
      console.log('serviceBattery', serviceBattery);
      const characteristicBatteryLevel = await serviceBattery.getCharacteristic(
        'battery_level',
      );

      const batteryLevelPromise = await characteristicBatteryLevel.readValue();

      const value = await batteryLevelPromise.getUint8(0);

      console.log('battery_level', value);
      addLogMessage(`Уровень батарейки: ${value} %`, LogDataLevel.info);
    }
    */

      // // ШАГ 3: Включаем измерение R-R интервалов
      console.log('connectDevice: получаем HR сервис');
      const hrService = await gattServer?.getPrimaryService('heart_rate');
      console.log('connectDevice: получаем HR характеристику');
      hrCharacteristic = await hrService?.getCharacteristic('heart_rate_measurement');
      console.log('connectDevice: hrCharacteristic', hrCharacteristic);
      if (hrCharacteristic) {
        hrCharacteristic.addEventListener('characteristicvaluechanged', processRRInterval);
        await hrCharacteristic.startNotifications();
      }
    } catch (error) {
      console.log(`connectDevice: (FATAL) reconnectDevice error: ${error}`);
      addLogMessage(`Произошла ошибка: ${error}`, LogDataLevel.warning);
      setStatusMeasure(false);

      // второй раз не будем переинициализировать, не понятно на какой стадии ошибка
    }
  };

  const reconnectDevice = async () => {
    if (!isMeasureStatus) {
      console.log('Хотел, но не смог');
      return;
    }

    try {
      console.log('reconnectDevice: Пересоединяемся с устройством');

      // ШАГ 2: Попробуем соединится с выбранным устройством
      console.log('reconnectDevice: получаем GATT сервер');
      const gattServer = await bluetoothDevice?.gatt?.connect();
    } catch (error) {
      console.log(`reconnectDevice: reconnectDevice error: ${error}`);
    }
  };

  const handleDisconnectedEvent = (event:Event) => {
    console.log('handleDisconnectedEvent');
    if (!isMeasureStatus) {
      console.log('Хотел, но не смог');
      return;
    }

    console.log('handleDisconnectedEvent: device ', bluetoothDevice);
    console.log('handleDisconnectedEvent: event', event);
    addLogMessage('Связь с утройством потеряна');

    console.log('handleDisconnectedEvent: setTimeout handleDisconnectedEvent');
    // setTimeout(() => { reconnectDevice(); }, timeoutReconnectSecond * 1000);
  };

  const onStopMeasure = async () => {
    console.log('onStopMeasure: device', bluetoothDevice);

    // сбрасываем флаги измерения
    setStatusMeasure(false);

    if (!bluetoothDevice) {
      return;
    }
    bluetoothDevice.removeEventListener('gattserverdisconnected', handleDisconnectedEvent);
    // останавливаем извещение
    if (hrCharacteristic) {
      await hrCharacteristic.stopNotifications();
    }
    // разрываем соединение
    if (bluetoothDevice.gatt?.connected) {
      bluetoothDevice.gatt.disconnect();
      console.log('onStopMeasure: bluetoothDevice disconnect');
    } else {
      console.log('onStopMeasure: bluetoothDevice already disconnect');
    }
  };

  const onStartMeasure = async () => {
    // if (!user.value) {
    //   notification.error({ message: 'Вы должны выбрать испытуемого' });
    //   return;
    // }
    // if (!context.value) {
    //   notification.error({ message: 'Вы должны выбрать контекст' });
    //   return;
    // }
    setLogData([]);
    addLogMessage('Запуск измерения. Поиск датчика.');

    try {
      // ШАГ 1: Выбираем наш датчик
      bluetoothDevice = await navigator.bluetooth.requestDevice({
        filters: [
          {
            services: [
              'heart_rate',
            ],
          },
        ],
      });

      // устанавливаем флаги запуска
      setStatusMeasure(true);

      console.log('device', bluetoothDevice);
      console.log('device.name', bluetoothDevice.name);

      // // начнем сессию
      // const currentDt = moment();
      // setUuid(uuidv4().toString());
      // const sessionCreate: SessionCreate = {
      //   user: user.value,
      //   start_date: currentDt.format(apiDateFormatWithTimeMs),
      //   context: context.value,
      // };
      // addMeasureData(currentDt.toDate(), uuid, MeasureCommandEnum.StartSession, JSON.stringify(sessionCreate));

      bluetoothDevice.addEventListener('gattserverdisconnected', handleDisconnectedEvent);

      console.log('Запускаем первое измерение');
      setTimeout(() => { connectDevice(); }, 0);
    } catch (error) {
      console.log(`onStartMeasure error: ${error}`);
      addLogMessage(`Произошла ошибка: ${error}`, LogDataLevel.warning);
    }
  };

  if (!supportsBluetooth) {
    return (
      <p>
        Ваш браузер не поддерживает BLE, попробуйте использовать Chrome и не забудьте выставить флаг
        chrome://flags/#enable-experimental-web-platform-features
      </p>
    );
  }

  return (
    <Row>
      <Col span={12} offset={6}>
        <h1>Измерение</h1>
        <Form>
          <Form.Item
            label="Испытуемый"
            name="username"
            rules={[{ required: true, message: 'Выберите испытуемого' }]}
          >
            <SelectCarrier carrier={carrier} setCarrier={setCarrier} />
          </Form.Item>
          <Form.Item
            label="Контекст"
            name="context"
            rules={[{ required: true, message: 'Выберите контекст' }]}
          >
            <SelectContex context={context} setContext={setContext} />
          </Form.Item>
        </Form>

        <Button onClick={onStartMeasure} disabled={isMeasure}>Начать измерение</Button>
        <Button onClick={onStopMeasure} disabled={!isMeasure}>Остановить измерение</Button>
        <div>Пульс: <b>{heartRate}</b></div>
        <RealTimeGraph item={realTimeGraphItem} setItem={setRealTimeGraphItem} />
        <LogWindow dataSource={logData} />

      </Col>
    </Row>

  );
};

export default Recorder;
