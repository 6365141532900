import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  Button, notification, Popconfirm, Space, Spin, Table,
} from 'antd';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {Carrier, MeasuringDevice, Token} from '../../models/types';
import api from '../../http';

const SensorList = () => {

  const queryClient = useQueryClient();

  const mutationDeleteSensor = useMutation(
      (id: number) => api.v1.v1MeasuringDeviceDelete(id));

  const onDeleteSensor = (record: Token) => {
    if (record.id) {
      mutationDeleteSensor.mutate(
          record.id,
          {
            onError: () => notification.error({ message: 'Ошибка' }),
            onSuccess: () => {
              queryClient.invalidateQueries(['MeasuringDeviceList']);
            },
          },
      );
    }
  };

  const {
    isLoading,
    isError,
    error,
    data: measuringDevice,
  } = useQuery(['MeasuringDeviceList'], () => api.v1.v1MeasuringDeviceList());

  const columns: ColumnsType<MeasuringDevice> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Cерийный номер',
      dataIndex: 'serial',
      key: 'serial',
    },
    {
      title: 'Идентификатор телефона',
      dataIndex: 'phone_uid',
      key: 'phone_uid',
    },
    {
      title: 'Тип устройства',
      dataIndex: 'device_type',
      key: 'device_type',
    },
    {
      title: 'Активирован',
      dataIndex: 'is_activate',
      key: 'is_activate',
      render: (_, record) => (
          <div>
            {record.is_activate ? 'Активирован' : 'Не активирован' }
          </div>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (record) => {
        return (
            <Popconfirm
                title="Вы уверены, что хотите удалить сенсор?"
                onConfirm={() => {
                  onDeleteSensor(record);
                }}
                okText="Да"
                cancelText="Нет"
            >
              <Button danger>
                Удаление
              </Button>
            </Popconfirm>
        );
      },
    },
  ];

  if (isError && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  return (
    <>
      <h1>Список измерительных сенсоров</h1>
      <Table columns={columns} dataSource={measuringDevice?.data} bordered rowKey="id" />
    </>
  );
};

export default SensorList;
