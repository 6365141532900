import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import {
  Col, Row, Spin, Table,
} from 'antd';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import {ColumnsType} from "antd/es/table";
import api from '../../../http';
import {
  appDateFormatWithoutSecond,
} from '../../../consts';

const SessionReport = () => {
  const { sessionId, filtered } = useParams() as {
    sessionId: string;
    filtered: string;
  };

  const useSessionReportData = () => {
    const [sessionQuery, eventsQuery] = useQueries({
      queries: [
        {
          queryKey: [`session_${sessionId}`],
          queryFn: () => api.v1.v1SessionsInfoExtraRead(sessionId),
        },
        {
          queryKey: [`events_report_${sessionId}`],
          queryFn: () => api.v1.v1EventsReportRead(sessionId),
        },
      ],
    });

    return {
      isLoading: sessionQuery.isLoading || eventsQuery.isLoading,
      isError: sessionQuery.isError || eventsQuery.isError,
      sessionData: sessionQuery.data,
      eventsData: eventsQuery.data,
    };
  };

  const {
    isLoading, isError, sessionData, eventsData,
  } = useSessionReportData();

  if (isError && axios.isAxiosError(isError)) {
    const err: AxiosError = isError as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
        <Spin />
      </div>
    );
  }

  const columns: ColumnsType<ParamType> = [
    {
      title: 'Параметры\\Название события',
      dataIndex: 'parameter',
      key: 'parameter',
      width: 250,
    },
  ];

  const newColumns = eventsData?.data.map((item) => {
    return {
      title: item.event_name ?? '',
      dataIndex: item.event_id?.toString() ?? '',
      key: item.event_id?.toString() ?? '',
      width: 100,
      render: (text: string, record: ParamType, index: number) => (
        <div>
          {index>0 &&  <div>{text}</div>}
          {index===0 && +text >= 180 &&  <div style={{color: "green"}}>{text}</div>}
          {index===0 && +text < 180 &&  <div style={{color: "red"}}>{text}</div>}
        </div>
      ),
    };
  });

  if (newColumns) {
    columns.push(...newColumns);
  }

  type ParamType = {
    [id: string]: string;
  };

  // Продолжительность
  const durationRow: ParamType = {};
  durationRow.key = 'duration_sec';
  durationRow.parameter = 'Продолжительность ФП (сек)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      durationRow[item.event_id.toString()] = item.duration_sec?.toString() ?? '';
    }
  });

  // ЧСС (уд/мин)
  const hrRow: ParamType = {};
  hrRow.key = 'hr';
  hrRow.parameter = 'ЧСС (уд/мин)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      hrRow[item.event_id.toString()] = item.hr?.toString() ?? '';
    }
  });

  // Мо (мс)
  const moRow: ParamType = {};
  moRow.key = 'mo';
  moRow.parameter = 'Мо (мс)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        moRow[item.event_id.toString()] = item.mo_filter?.toString() ?? '';
      }else {
        moRow[item.event_id.toString()] = item.mo?.toString() ?? '';
      }
    }
  });

  // LF (мс²)
  const LFRow: ParamType = {};
  LFRow.key = 'lf';
  LFRow.parameter = 'LF (мс²)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        LFRow[item.event_id.toString()] = item.LF_filter?.toString() ?? '';
      }else{
        LFRow[item.event_id.toString()] = item.LF?.toString() ?? '';
      }
    }
  });

  // HF (мс²)
  const HFRow: ParamType = {};
  HFRow.key = 'hf';
  HFRow.parameter = 'HF (мс²)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        HFRow[item.event_id.toString()] = item.HF_filter?.toString() ?? '';
      }else{
        HFRow[item.event_id.toString()] = item.HF?.toString() ?? '';
      }
    }
  });

  // TP (мс²)
  const TPRow: ParamType = {};
  TPRow.key = 'tp';
  TPRow.parameter = 'TP (мс²)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        TPRow[item.event_id.toString()] = item.TP_filter?.toString() ?? '';
      }else{
        TPRow[item.event_id.toString()] = item.TP?.toString() ?? '';
      }
    }
  });

  // ИВБ (мс²)
  const IVBRow: ParamType = {};
  IVBRow.key = 'ivb';
  IVBRow.parameter = 'ИВБ (мс²)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        IVBRow[item.event_id.toString()] = item.ivb_filter?.toString() ?? '';
      }else{
        IVBRow[item.event_id.toString()] = item.ivb?.toString() ?? '';
      }
    }
  });

  // SDNN
  const SDNNRow: ParamType = {};
  SDNNRow.key = 'sdnn';
  SDNNRow.parameter = 'SDNN';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        SDNNRow[item.event_id.toString()] = item.sdnn_filter?.toString() ?? '';
      }else{
        SDNNRow[item.event_id.toString()] = item.sdnn?.toString() ?? '';
      }
    }
  });

  // УОС(%)
  const YOCRow: ParamType = {};
  YOCRow.key = 'yoc';
  YOCRow.parameter = 'YOC';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      YOCRow[item.event_id.toString()] = item.yoc?.toString() ?? '';
    }
  });

  // Экстрасистолы
  const ExtrasystolesRow: ParamType = {};
  ExtrasystolesRow.key = 'extrasystoles';
  ExtrasystolesRow.parameter = 'Экстрасистолы';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      ExtrasystolesRow[item.event_id.toString()] = item.extrasystoles?.toString() ?? '';
    }
  });

  // Спайки
  const SpikesRow: ParamType = {};
  SpikesRow.key = 'spikes';
  SpikesRow.parameter = 'Спайки';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      SpikesRow[item.event_id.toString()] = item.spikes?.toString() ?? '';
    }
  });

  // P_edit
  const PEditRow: ParamType = {};
  PEditRow.key = 'P_edit';
  PEditRow.parameter = 'Доля артефактов в записи (%)';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      PEditRow[item.event_id.toString()] = item.P_edit?.toString() ?? '';
    }
  });

  // pNN50
  const pNN50Row: ParamType = {};
  pNN50Row.key = 'pNN50';
  pNN50Row.parameter = 'pNN50';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        pNN50Row[item.event_id.toString()] = item.pNN50_filter?.toString() ?? '';
      }else {
        pNN50Row[item.event_id.toString()] = item.pNN50?.toString() ?? '';
      }
    }
  });

  // Физиологическая норма
  const physioRow: ParamType = {};
  physioRow.key = 'physioRow';
  physioRow.parameter = 'Физиологическая норма';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        physioRow[item.event_id.toString()] = item.physio_norm_filter?.toString() ?? '';
      }else {
        physioRow[item.event_id.toString()] = item.physio_norm?.toString() ?? '';
      }
    }
  });

  // Донозологические состояния
  const prenosCondRow: ParamType = {};
  prenosCondRow.key = 'prenos_cond';
  prenosCondRow.parameter = 'Донозологические состояния';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        prenosCondRow[item.event_id.toString()] = item.prenos_cond_filter?.toString() ?? '';
      }else {
        prenosCondRow[item.event_id.toString()] = item.prenos_cond?.toString() ?? '';
      }
    }
  });

  // Преморбидные состояния
  const premorCondRow: ParamType = {};
  premorCondRow.key = 'premor_cond';
  premorCondRow.parameter = 'Преморбидные состояния';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        premorCondRow[item.event_id.toString()] = item.premor_cond_filter?.toString() ?? '';
      }else {
        premorCondRow[item.event_id.toString()] = item.premor_cond?.toString() ?? '';
      }
    }
  });

  // Патологические состояния
  const patholRow: ParamType = {};
  patholRow.key = 'pathol_cond';
  patholRow.parameter = 'Патологические состояния';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        patholRow[item.event_id.toString()] = item.pathol_cond_filter?.toString() ?? '';
      }else {
        patholRow[item.event_id.toString()] = item.pathol_cond?.toString() ?? '';
      }
    }
  });

  // Q_edit
  const QeditRow: ParamType = {};
  QeditRow.key = 'Q_edit';
  QeditRow.parameter = 'Q редактируемое';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        QeditRow[item.event_id.toString()] = item.Q_edit_filter?.toString() ?? '';
      }else {
        QeditRow[item.event_id.toString()] = item.Q_edit?.toString() ?? '';
      }
    }
  });

  // Функциональный резерв
  const FRRow: ParamType = {};
  FRRow.key = 'FN';
  FRRow.parameter = 'Функциональный резерв';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        FRRow[item.event_id.toString()] = item.FR_filter?.toString() ?? '';
      }else {
        FRRow[item.event_id.toString()] = item.FR?.toString() ?? '';
      }
    }
  });

  // Степень напряжения
  const SNRow: ParamType = {};
  SNRow.key = 'SN';
  SNRow.parameter = 'Степень напряжения';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        SNRow[item.event_id.toString()] = item.SN_filter?.toString() ?? '';
      }else {
        SNRow[item.event_id.toString()] = item.SN?.toString() ?? '';
      }
    }
  });

  // Уровень адаптационных рисков
  const adapRiskRow: ParamType = {};
  adapRiskRow.key = 'adap_risk';
  adapRiskRow.parameter = 'Уровень адаптационных рисков';

  eventsData?.data.forEach((item) => {
    if (item.event_id) {
      if (filtered==='filtered') {
        adapRiskRow[item.event_id.toString()] = item.adap_risk_filter?.toString() ?? '';
      }else {
        adapRiskRow[item.event_id.toString()] = item.adap_risk?.toString() ?? '';
      }
    }
  });

  const dataSource = [
    durationRow,
    hrRow,
    moRow,
    LFRow,
    HFRow,
    TPRow,
    IVBRow,
    SDNNRow,
    YOCRow,
    ExtrasystolesRow,
    SpikesRow,
    PEditRow,
    pNN50Row,
    physioRow,
    prenosCondRow,
    premorCondRow,
    patholRow,
    QeditRow,
    FRRow,
    SNRow,
    adapRiskRow,
  ];

  return (
    <>
      <Row>
        <Col span={12} offset={2}>
          <h1>
            РЕЗУЛЬТАТЫ ССТРС (КИГ, {filtered==='filtered'?'фильтр':'исходные'})
          </h1>
        </Col>
      </Row>
      <Row>
        <Col span={2} offset={2}>
          Персона:
        </Col>
        <Col>
          <b>{sessionData?.data.carrier_nick}</b>
        </Col>
      </Row>
      <Row>
        <Col span={2} offset={2}>
          Возраст:
        </Col>
        <Col />
      </Row>
      <Row>
        <Col span={2} offset={2}>
          Дата измерения:
        </Col>
        <Col>
          <b>
            {dayjs(sessionData?.data.start_date).format(
              appDateFormatWithoutSecond,
            )}
          </b>
        </Col>
      </Row>
      <Row>
        <Col span={2} offset={2}>
          Контекст:
        </Col>
        <Col>
          <b>{sessionData?.data.context_name}</b>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <hr />
          <Table
            columns={columns}
            dataSource={dataSource}
            tableLayout="fixed"
            style={{width:"10%"}}
            bordered
            rowKey="key"
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default SessionReport;
