import axios from 'axios';
import {AutoComplete, notification} from 'antd';
import { useNavigate } from 'react-router-dom';
import { AuthResponse } from '../models/response/AuthResponse';
import {
  clearTokens,
  getLocalAccessToken,
  getLocalRefreshToken,
  setTokens,
} from '../utils/localStorage';
import { Api } from '../models/types';

// Пример отсюда https://www.youtube.com/watch?v=fN25fMQZ2v0
// https://github.com/utimur/fullstack-jwt-auth
// https://codevoweb.com/react-query-context-api-axios-interceptors-jwt-auth/

export const API_URL = `${process.env.REACT_APP_API_URL}/api`;

// базовый API клиент
const api = new Api({
  baseURL: API_URL,
  // в реквесте добавляем всегда ключ авторизации
  securityWorker: (accessToken) => {
    if (!accessToken){
      accessToken = getLocalAccessToken();
    }
    // console.log(`Bearer ${accessToken}`);

    return {
      headers: { Authorization: `Bearer ${accessToken}` },
  }
  },
});

// API клиент используемый для всяких штук без авторизации
export const apiWithoutInterceptor = new Api({
  baseURL: API_URL,
});

export const refreshAccessToken = async () => {
  const refreshToken = getLocalRefreshToken();
  console.log(refreshToken);
  if (refreshToken) {
    const response = await axios.post<AuthResponse>(
      `${API_URL}/token/refresh/`,
      { refresh: refreshToken },
    );
    setTokens(response.data);
    // попросим API использовать токен в дальнейшем
    api.setSecurityData(response.data.access);
  }
};

// в ответе проверим один раз, не истек ли токен и попробуем его обновить
api.instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const originalRequest = error.config;
      const errCode = error.response.data.code;
      if (
        error.response.status === 401 &&
        errCode === 'token_not_valid' &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        await refreshAccessToken();
        return await api.instance(originalRequest);
      }
    } catch (e) {
      // console.log('Не авторизован', e);
    }
    return Promise.reject(error);
  },
);

/* api.instance.interceptors.response.use((config) => {
  return config;
}, async (error) => {
  const originalRequest = error.config;
  // console.log('interceptors.response', originalRequest);
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const refreshToken = getLocalRefreshToken();
      if (refreshToken) {
        const response = await axios.post<AuthResponse>(`${API_URL}/token/refresh/`, { refresh: refreshToken });
        setTokens(response.data);
        // попросим API использовать токен в дальнейшем
        api.setSecurityData(response.data.access);
      }
      // TODO Запрос конструируем руками - тут всяко забыли еще что то проборосить
      return await api.request({
        secure: true,
        path: originalRequest.url,
        method: originalRequest.method,
      });
    } catch (e) {
      //  после двух попыток сносим токены
      clearTokens();
      //  https://www.bezkoder.com/react-refresh-token/
      console.log('НЕ АВТОРИЗОВАН', e);

      return Promise.reject(e);
    }
  }
  return Promise.reject(error);
}); */

export function axiosErrorHandler(err: any): string {
  let message: string;
  if (axios.isAxiosError(err) && err.response) {
    message = JSON.stringify(err.response.data);
  } else {
    message = String(err);
  }
  return message;
}

export default api;
