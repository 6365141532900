import { useState } from 'react';

// https://github.com/utimur/react-fundamental-course/blob/master/src/hooks/useFetching.js

const useFetching = (
  callback: any,
):[((...args: any[]) => Promise<void>), boolean, string] => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetching = async (...args: any[]) => {
    try {
      setIsLoading(true);
      await callback(...args);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return [fetching, isLoading, error];
};

export default useFetching;
