import React from 'react';

import { Card } from 'antd';
import { Link } from 'react-router-dom';

const Laterometr = () => {
  return (
    <div />

  );
};

export default Laterometr;
