import React, { useState } from 'react';
import {
  Button, Input, Modal, notification, Popconfirm, Spin, Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Token, TokenCreate } from '../models/types';
import api from '../http';
import AddTokenModal from '../components/UI/Token/Index';
import { AddTokenModalValues } from '../components/UI/Token/types';

const Account = () => {
  const [openAddTokenForm, setOpenAddTokenForm] = useState(false);
  const [disableAddTokenButton, setDisableAddTokenButton] = useState(false);

  const queryClient = useQueryClient();

  const mutationCreateToken = useMutation((data: TokenCreate) => api.v1.v1ProfileTokensCreate(data));
  const mutationDeleteToken = useMutation((id: number) => api.v1.v1ProfileTokensDelete(id));
  const mutationSendEmailActivateToken = useMutation((id: number) => api.v1.v1ProfileTokensSendActivateEmail(id));

  const onCreateToken = (values: AddTokenModalValues) => {
    mutationCreateToken.mutate(
      { key: values.token },
      {
        onSuccess: (data) => {
          if (data.data.id) {
            mutationSendEmailActivateToken.mutate(data.data?.id);
            queryClient.invalidateQueries(['token_list']);
            notification.success({ message: 'На электронную почту было выслано письмо для активации идентификатора смартфона' });
          }
        },
      },
    );

    setOpenAddTokenForm(false);
  };

  const onDeleteToken = (record: Token) => {
    if (record.id) {
      mutationDeleteToken.mutate(
        record.id,
        {
          onError: () => notification.error({ message: 'Ошибка' }),
          onSuccess: () => {
            queryClient.invalidateQueries(['token_list']);
          },
        },
      );
    }
  };

  const tokenColumns: ColumnsType<Token> = [
    {
      title: 'Идентификатор',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'UID телефона',
      dataIndex: 'phone_uid',
      key: 'phone_uid',
    },
    {
      title: 'Телефон',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'Активирован',
      dataIndex: 'is_activate',
      key: 'is_activate',
      render: (_, record) => (
        <div>
          {record.is_activate ? 'Активирован' : 'Не активирован' }
        </div>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (record) => {
        return (
          <Popconfirm
            title="Вы уверены, что хотите удалить идентификатор?"
            onConfirm={() => {
              onDeleteToken(record);
            }}
            okText="Да"
            cancelText="Нет"
          >
            <Button danger>
              Удаление
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const useAccountData = () => {
    const {
      isLoading: tokenIsLoading,
      error: tokenError,
      data: tokenData,
    } = useQuery(
      ['token_list'],
      () => api.v1.v1ProfileTokensList(),
      {
        retryDelay: 1,
        // onSuccess: (data) => {
        //   setDisableAddTokenButton(data.data.length > 0);
        // },
      },
    );

    const isError = tokenError;
    const isLoading = tokenIsLoading;

    return {
      isError,
      isLoading,
      tokenData,
    };
  };

  const {
    isError,
    isLoading,
    tokenData,
  } = useAccountData();

  if (isError && axios.isAxiosError(isError)) {
    const err: AxiosError = isError as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  return (
    <>
      <h1>Идентификаторы смартфонов</h1>
      {/* <Button */}
      {/*  onClick={() => { setOpenAddTokenForm(true); }} */}
      {/*  disabled={disableAddTokenButton} */}
      {/* >Добавить */}
      {/* </Button> */}
      <Table
        columns={tokenColumns}
        dataSource={tokenData?.data}
        bordered
        rowKey="id"
        pagination={false}
      />
      <AddTokenModal
        open={openAddTokenForm}
        onCreate={onCreateToken}
        onCancel={() => { setOpenAddTokenForm(false); }}
      />
    </>
  );
};

export default Account;
