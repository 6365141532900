import React, { useEffect, useState } from 'react';
import { YMaps, Map, GeoObject } from '@pbe/react-yandex-maps';
import { useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import { SessionGeoGraphProps, SessionTimeGraphProps } from './types';
import { GEODataMeasure, HRDataMeasure } from '../../../models/types';

const GEOGraph: React.FC<SessionGeoGraphProps> = ({ chartName, queryFn }) => {
  // const [geoData, setGeoData] = useState<GEODataMeasure[]>([]);

  // useEffect(() => {
  //   // стартуем обновлялку сигнала - раз в 5 секунд
  //   const updateData = async () => {
  //     try {
  //       console.log('Update', chartName);
  //       const data = await queryFn();
  //       setGeoData(data.data);
  //     } catch (e) {
  //       console.error(e);
  //     } finally {
  //       // setTimeout(() => {
  //       //   updateData();
  //       // }, 1000);
  //     }
  //   };
  //
  //   updateData();
  // }, []);

  const { isLoading, isError, data } = useQuery(
    ['geomap'],
    () => queryFn,
  );

  if (isError) {
    return <>Произошла ошибка: ${isError}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  const geoData = (data?.data as GEODataMeasure[]).map((item) => {
    return [item.latitude, item.longitude];
  });

  const startCoordinate = [56.3287, 44.002];

  return (
    <YMaps>
      <Map
        defaultState={{
          center: geoData.length ===0 ? startCoordinate : geoData[0] ,
          zoom: 13,
          // controls: ['zoomControl', 'rulerControl'],
        }}
        width="100%"
      >
        <GeoObject
          geometry={{
            type: 'LineString',
            coordinates: geoData,
          }}
          options={{
            geodesic: true,
            strokeWidth: 5,
            strokeColor: '#F008',
          }}
        />
      </Map>
    </YMaps>
  );
};

export default GEOGraph;
