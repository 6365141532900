import React, {
  useEffect,
  useLayoutEffect,
  useRef, useState,
} from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import dayjs from 'dayjs';
import { SessionRRTimeGraph, SessionTimeGraphProps } from './types';
import {
  EventCreate,
  RRDataMeasureCalc,
} from '../../../models/types';

const RRTimeGraph: React.FC<SessionTimeGraphProps<SessionRRTimeGraph>> = ({
  timeData : {
    wide_periods,
    sensor_name,
    showFilteredData,
    queryFn,
    queryFilterFn,
    markers,
    markerIdsVisible,
    minX,
    maxX,
    minY,
    maxY,
    onCreateEventManual
  },
  chartName,
  graphRangeX,
  setGraphRangeX,
}) => {
  let cursorSelectRangeStartX: Date = new Date();
  const [
    markerRange, setMarkerRange
  ]  = useState<Array<am5.DataItem<am5xy.IDateAxisDataItem>>>([])

  const chartRef = useRef<am5xy.XYChart | null>(null);
  const seriesRef = useRef<am5xy.LineSeries | null>(null);
  const seriesFilterRef = useRef<am5xy.LineSeries | null>(null);
  const xAxisRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer> | null>(null);

  const [rrCount, setRRCount]= useState<number>();

  /*  Первичная инициализация графика с данными */
  useLayoutEffect(() => {
    // создаем основу графика
    const root = am5.Root.new(chartName);
    // прячем лого
    root._logo?.dispose();

    // навешиваем тему
    root.setThemes([am5themes_Animated.new(root)]);

    // создаем отрисовку
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        // panX: true,
        // panY: true,
        // wheelX: 'panX',
        //  wheelY: 'zoomX',
      }),
    );

    chartRef.current = chart

    // создадим рамку на графике
    const background = chart.plotContainer.get("background");
    if (background){
      background.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
        stroke: am5.color('#000'),
        fill: am5.color(0xffffd1),
        fillOpacity: 1.0,
      })
    }

    // // верхний ZOOM
    // chart.set(
    //   'scrollbarX',
    //   am5.Scrollbar.new(root, { orientation: 'horizontal' }),
    // );
    // chart.set('scrollbarY', am5.Scrollbar.new(root, { orientation: 'vertical' }));

    // отключим кнопку ZOOM
    chart.zoomOutButton.set("forceHidden", true);

    // создадим курсор
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'selectX',
      }),
    );

    // создаем оси
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        // min: new Date(2023, 2, 23, 21, 10, 0).getTime(),
        min: minX.getTime(),
        max: maxX ? maxX.getTime() : undefined,
        strictMinMax: true,
        baseInterval: {
          timeUnit: 'millisecond',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );

    xAxisRef.current = xAxis;

    // cursor.lineY.set('visible', false);
    // обрабатываем начало выделения
    cursor.events.on('selectstarted', () => {
      const posX = cursor.getPrivate('positionX');
      if (posX) {
        cursorSelectRangeStartX = xAxis.positionToDate(
          xAxis.toAxisPosition(posX),
        );
      }
    });
    // обрабатываем конец выделения
    cursor.events.on('selectended', () => {
      const posX = cursor.getPrivate('positionX');
      if (posX) {
        let cursorSelectRangeEndX = xAxis.positionToDate(
          xAxis.toAxisPosition(posX),
        );
        // если пользователь араб, выделяет справ налево - то меняем параметры
        if (cursorSelectRangeEndX < cursorSelectRangeStartX){
          [cursorSelectRangeStartX, cursorSelectRangeEndX] = [cursorSelectRangeEndX, cursorSelectRangeStartX]
        }

        onCreateEventManual({
          event_start: cursorSelectRangeStartX,
          event_end: cursorSelectRangeEndX,
        });
      }
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: minY,
        max: maxY,
        strictMinMax: true,
        renderer: am5xy.AxisRendererY.new(root, {
          // метки по Y будут внутри оси
          inside: true,
        }),
        // фиксим ошибку, что внутри метки не видны при полной непрозрачности
        layer:1,
      }),
    );

    // смещение меток по Y оси
    yAxis.get('renderer').labels.template.setAll({
      dy:10,
    });

    // создаем серии
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0x000000),
        // tooltip: am5.Tooltip.new(root, {
        //   labelText: '{valueY}',
        //   dy: -5,
        // }),
      }),
    );


    seriesRef.current = series;

    const seriesFilter = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series Filter',
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0xff0000),
      }),
    );
    seriesFilter.set('fill', am5.color(0xff0000));

    seriesFilterRef.current = seriesFilter;

    // пока тут попробуем отметить широкие зоны
    wide_periods.forEach((item) => {
      const widePeriodItem = xAxis.makeDataItem({});
      widePeriodItem.set(
        'value',
        am5.time.add(item.start_dt, 'millisecond', 1).getTime(),
      );
      if (item.end_dt) {
        widePeriodItem.set(
          'endValue',
          am5.time.add(item.end_dt, 'millisecond', 1).getTime(),
        );
      }
      xAxis.createAxisRange(widePeriodItem);
      widePeriodItem.get('axisFill')?.setAll({
        fill: chart.get('colors')?.getIndex(7),
        fillOpacity: 0.4,
        visible: true,
      });
      // widePeriodItem.get('grid')?.set('forceHidden', true);
      // widePeriodItem.get('grid')?.set('visible', true);
    });

    return () => {
      root.dispose();
    };
  }, []);

  /*  Обновление исходного измеряемого сигнала */
  useEffect(() => {
    const updateData = async () => {
      try {
        console.log('Update', chartName);
        const data = await queryFn();
        const rrCalcData = data.data as RRDataMeasureCalc[];
        const procRRCalc: any[] = [];
        rrCalcData.forEach((item) => {
          procRRCalc.push({
            date: am5.time
              .add(dayjs(item.dt).toDate(), 'millisecond', 1)
              .getTime(),
            // date: item.id,
            value: item.value,
          });
          // console.log(am5.time.add(moment(item.dt).toDate(), 'millisecond', 1).getTime());
        });

        seriesRef.current?.data.setAll(procRRCalc);
        setRRCount(rrCalcData.length);

        // console.log('rrData', rrCalcData[0]);
      } catch (e) {
        console.error(e);
      } finally {
        // setTimeout(() => {
        //   updateData();
        // }, 1000);
      }
    };

    updateData();
  }, []);

  /*  Обновление фильтрованного измеряемого сигнала */
  useEffect(() => {

    if (!showFilteredData){
      return;
    }

    const updateData = async () => {
      try {
        console.log('Update filter', chartName);
        const data = await queryFilterFn();
        const rrFilterData = data.data as RRDataMeasureCalc[];
        const procRRFilter: any[] = [];
        rrFilterData.forEach((item) => {
          procRRFilter.push({
            date: am5.time
              .add(dayjs(item.dt).toDate(), 'millisecond', 1)
              .getTime(),
            // date: item.id,
            value: item.value,
          });
          // console.log(am5.time.add(moment(item.dt).toDate(), 'millisecond', 1).getTime());
        });

        seriesFilterRef.current?.data.setAll(procRRFilter);

        // console.log('rrData', rrCalcData[0]);
      } catch (e) {
        console.error(e);
      } finally {
        // setTimeout(() => {
        //   updateData();
        // }, 1000);
      }
    };

    updateData();
  }, []);

  /* Тут мы делаем переотрисовку маркеров */
  useEffect(() => {

    const newMarkerRange: Array<am5.DataItem<am5xy.IDateAxisDataItem>> = []

    // сначала удалим все, что навставляли
    markerRange.forEach((item) => {
      xAxisRef.current?.axisRanges.removeValue(item);
    })

    // потом вставим
    markers.forEach((item) => {
      if (markerIdsVisible.includes(item.marker_id)) {

        const markerItem = xAxisRef.current?.makeDataItem({});
        markerItem?.set(
            'value',
            am5.time.add(item.start_dt, 'millisecond', 1).getTime(),
        );
        if (item.end_dt) {
          markerItem?.set(
              'endValue',
              am5.time.add(item.end_dt, 'millisecond', 1).getTime(),
          );
        }
        if (markerItem) {
          const range = xAxisRef.current?.createAxisRange(markerItem);
          if (range) {
            newMarkerRange.push(range);
          }
          markerItem.get('axisFill')?.setAll({
            fill: chartRef.current?.get('colors')?.getIndex(12),
            fillOpacity: 1,
            visible: true,
          });
        }
      }
    });

    setMarkerRange(newMarkerRange);

  }, [markerIdsVisible, markers])

  useEffect(() => {
    if (!setGraphRangeX) {
      if (graphRangeX) {
        xAxisRef.current?.setAll(graphRangeX);
      }
    }
  }, [graphRangeX]);

  return (
    <>
      <div>Name: <b>RR</b>, points: <b>{rrCount}</b>,  sensor: <b>{sensor_name}</b></div>
      <div id={chartName} style={{ width: '100%', height: '250px' }} />
    </>
  );
};

export default RRTimeGraph;
