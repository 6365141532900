import React from 'react';
import { Form, Input, Modal } from 'antd';
import AddTokenModalProps from './types';

const AddTokenModal = ({ open, onCreate, onCancel }:AddTokenModalProps) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="Добавление нового идентификатора"
      open={open}
      okText="Добавить"
      cancelText="Отменить"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name="token"
          label="Идентификатор"
          rules={[
            {
              required: true, message: 'Необходимо добавить идентификатор',
            },
            {
              message: 'Идентификатор должен быть длинной от 2 до 20 символов', min: 2, max: 20,
            },
            {
              pattern: RegExp('([1-9a-zA-Z]\\s*)+'),
              message: 'Введите цифры и буквы в английском алфавите',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTokenModal;
