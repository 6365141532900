import React from 'react';

import { Card } from 'antd';
import { Link } from 'react-router-dom';

const ValenceInterference = () => {
  return (
    <div />
  );
};

export default ValenceInterference;
