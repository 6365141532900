// парсим полученный пакет от heart-rate по спецификации
// https://www.bluetooth.com/specifications/specs/heart-rate-service-1-0/
export default function parseHeartRateCharacteristic(data: DataView): [number, number[]] {
  // первый байт у нас флаги
  const flags = data.getUint8(0);
  // console.log('FLAGS', flags);
  // смещение при чтении данных
  let index = 1;

  // получение HR
  // определим у нас HR байтовые или двухбайтовые
  const rate16Bits = flags & 0x1;
  let heartRate: number;
  if (rate16Bits) {
    heartRate = data.getUint16(index, true);
    index += 2;
  } else {
    heartRate = data.getUint8(index);
    index += 1;
  }

  // получение RR интервалов
  const rrIntervalPresent = flags & 0x10;
  const rrIntervals: number[] = [];
  if (rrIntervalPresent) {
    for (; index + 1 < data.byteLength; index += 2) {
      rrIntervals.push(data.getUint16(index, true));
    }
  }

  return [heartRate, rrIntervals];
}
