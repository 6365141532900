import React from 'react';
import {
  Button, Form, Input, Typography, notification, Col, Row, Space,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { setUserData } from '../../redux/slices/user';
import { IUser } from '../../models/IUser';
import { useAppDispatch } from '../../hooks/appStore';
import { JwtCustomToken } from '../../models/response/AuthResponse';
import { SiteTokenObtainPair } from '../../models/types';
import { setTokens } from '../../utils/localStorage';
import api, { apiWithoutInterceptor } from '../../http';

const { Title } = Typography;

const Login = () => {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async ({ username, password }: SiteTokenObtainPair) => {
    console.log('Submit:', username, password);

    try {
      // отправляем логин и пароль - получаем JWT токен
      // const response = await AuthService.login(username, password);
      const { data } = await apiWithoutInterceptor.token.tokenObtainCreate({ username, password });
      console.log('response', data);

      // попросим API использовать токен в дальнейшем
      api.setSecurityData(data.access);

      // закинем токены в хранилище
      setTokens(data);

      // выставим пользователя в store
      // console.log('token', token);
      const tokenInfo = jwt_decode<JwtCustomToken>(data.access);
      // console.log('tokenInfo', tokenInfo);

      const user: IUser = {
        id: tokenInfo.user_id,
        username: tokenInfo.username,
        is_superuser: tokenInfo.is_superuser,
      };
      appDispatch(setUserData(user));

      navigate('/telemetry', { replace: true });
    } catch (err) {
      let errorMessage = 'Ошибка авторизации';
      if (axios.isAxiosError(err)) {
        errorMessage = err.response?.data.detail;
        console.log('Axios error', err.message, errorMessage);
      }

      notification.error({ message: errorMessage });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Row>
        <Col span={12} offset={6}>
          <h1 style={{ textAlign: 'center' }}>КОГНИТОМ</h1>
          <h3 style={{ textAlign: 'center' }}>Кафедра психофизиологии факультета социальных наук Университета им. Н. И. Лобачевского</h3>
          <hr />
          <br />
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <Title level={3} style={{ textAlign: 'center' }}>Вход в личный кабинет:</Title>

          <Form
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              label="Имя пользователя"
              name="username"
              rules={[{ required: true, message: 'Введите свое имя!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Пароль"
              name="password"
              rules={[{ required: true, message: 'Введите свой пароль!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="submit">
              <Space>
                <Button type="primary" htmlType="submit">
                  Вход
                </Button>
                <Link to="/auth/registration"><Button>Регистрация</Button></Link>
                <Link to="/auth/password_reset">Забыли пароль?</Link>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Login;
