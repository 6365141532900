import {Button, Col, Popconfirm, Row, Space, Spin, Table, TableProps, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import dayjs from "dayjs";
import {CheckCircleFilled, CloseCircleFilled, DeleteTwoTone} from "@ant-design/icons";
import {Expert, LogInformation, SessionFrontend} from "../../models/types";
import api from "../../http";
import appDateFormat, {appDateFormatWithTime} from "../../consts";
import {TableParams} from "../../models/ITable";
import {getFirstValue} from "../../models/helper";

const ExpertList = () => {


  const getQueryKey = () => {
    return ['ExpertList']
  };


  const columns: ColumnsType<Expert> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Эксперт',
      dataIndex: 'username',
      key: 'username',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'date_joined',
      key: 'date_joined',
      width: 50,
      render: (_, record) => (
        <div>
          {dayjs(record.date_joined).format(appDateFormat)}
        </div>
      ),
    },
    {
      title: 'Дата рождения',
      dataIndex: 'date_birth',
      key: 'date_birth',
      width: 50,
      sorter: true,
    },
    {
      title: 'Пол',
      dataIndex: 'gender',
      key: 'gender',
      width: 30,
      render: (_, record) => (
        <div>
          { record.gender && +record.gender === 1 && `М`}
          { record.gender && +record.gender === 2 && `Ж`}
        </div>
      ),
    },
    {
      title: 'Активен',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (_, record) => (
        <div>
          { record.is_active && record.is_active && <CheckCircleFilled style={{ color: "green" }} />}
          { record.gender && !record.is_active && <CloseCircleFilled style={{ color: "red" }} />}
        </div>
      ),
      width: 30,
    },
    {
      title: 'О себе',
      dataIndex: 'about',
      key: 'about',
    },
  ]

  const {
    isLoading,
    error,
    data: dataExpertList,
  } = useQuery(getQueryKey(), () => {

    return api.v1.v1ExpertsList();
  });

  if (error && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}><Spin/></div>;
  }

  return (
    <Row>
      <Col span={24} style={{padding: 20}}>
        <h1>Список экспертов</h1>
        <Table
          columns={columns}
          dataSource={dataExpertList?.data}
          bordered
          rowKey="id"
        />
      </Col>
    </Row>
  );
}

export default ExpertList;