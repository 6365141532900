import React, {useEffect, useState} from 'react'
import { ColumnsType } from 'antd/es/table';
import "./Telemetry.css"
import {
  CheckCircleTwoTone, CloseCircleOutlined, DeleteTwoTone,
  HeartTwoTone, ReloadOutlined,
} from '@ant-design/icons';
import {
  Button, Checkbox, Col, notification, PaginationProps, Popconfirm, Row, Space, Spin, Table, Tag, Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import {TableProps} from "antd/lib";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {SessionFrontend} from '../models/types';
import { appDateFormatWithTime } from '../consts';
import api from '../http';
import TelemetrySearchForm from '../components/UI/TelemetrySearch/TelemetrySearchForm';
import {SessionFrontendExtra} from "../models/Session";

const Telemetry = () => {

  const DEFAULT_PAGE_SIZE = 15

  // const [data, setData] = useState<SessionFrontendList[]>();

  const [filterNick, setFilterNick] = useState<string | undefined>();
  const [filterGender, setFilterGender] = useState<string | undefined>();
  const [filterContexts, setFilterContexts] = useState<string[]>([]);
  const [filterMarkers, setFilterMarkers] = useState<string[]>([]);
  const [filterStatus, setFilterStatus] = useState<number|undefined>();
  const [filterCarrierOldStart, setFilterCarrierOldStart] = useState<number|undefined>();
  const [filterCarrierOldEnd, setFilterCarrierOldEnd] = useState<number|undefined>();

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

  const mutationSessionDelete = useMutation((session_id: string) => api.v1.v1SessionsDeleteDelete(session_id));
  const mutationSessionRecalculate = useMutation((session_id: string) => api.v1.v1SessionsRecalculateRead(session_id));

  const queryClient = useQueryClient();

  const getQueryKey = () => {
    return [
        'sessionListExtraList',
        filterNick,
        filterGender,
        filterContexts,
        filterMarkers,
        filterStatus,
        filterCarrierOldStart,
        filterCarrierOldEnd
    ];
  }

  const {
    isLoading,
    isError,
    error,
    data: sessions,
  } = useQuery(
      getQueryKey(),
    () => api.v1.v1SessionsListExtraList({
      nick: filterNick,
      gender: filterGender,
      contexts: filterContexts,
      markers: filterMarkers,
      status: filterStatus,
      old_start: filterCarrierOldStart,
      old_end: filterCarrierOldEnd
    }),
  );

  const onDeleteSession = (record: SessionFrontend) => {
    if (record.id) {
      mutationSessionDelete.mutate(
          record.id.toString(),
          {
            onSuccess:() => {
              queryClient.invalidateQueries(getQueryKey());
            }
          }
      )
    }
  }

  const onRecalculateSession = (record: SessionFrontend) => {
    if (record.id) {
      mutationSessionRecalculate.mutate(
        record.id.toString(),
        {
          onSuccess:() => {
            notification.info({
              message: 'Сессия будет пересчитана. через некоторое время',
            });
          }
        }
      )
    }
  }

  const onCloseSession = (record: SessionFrontend) => {
    console.log('onCloseSession');
  }

  const clearLocalKeys = () => {
    // eslint-disable-next-line no-plusplus
      for(let i=0; i<localStorage.length; i++) {
        const key = localStorage.key(i);
        if(key && key.startsWith('session_graphs_')) {
          // console.log(key);
          localStorage.removeItem(key);
        }
      }
  }

  useEffect(() => {
    clearLocalKeys();
  }, []);

  const onChange: TableProps<SessionFrontend>['onChange'] = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
      // console.log('params', filters);
      if ('status' in filters && filters.status){
          console.log(filters.status)
          setFilterStatus(+filters.status[0])
      }else{
          console.log('reset status')
          setFilterStatus(undefined)
      }
  };

  const showTotalRecords: PaginationProps['showTotal'] = (total) => `Записей: ${total}`;

  const optionsGraph = [
    { label: 'RR_F', value: 'rr_f' },
    { label: 'HR', value: 'hr' },
    { label: 'SP', value: 'sp' },
    { label: 'E', value: 'ex_enrg' },
    { label: 'M', value: 'geo' },
  ];

  const onChangeGraphs = (checkedValues: CheckboxValueType[], record: SessionFrontendExtra) => {
    // console.log('checked = ', checkedValues);
    localStorage.setItem(
      `session_graphs_${record.id}`,
      JSON.stringify(checkedValues)
    )
  };

  const columns: ColumnsType<SessionFrontendExtra> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: (a, b) => {
        if (a.id && b.id) {
          return a.id - b.id;
        }
        return 1;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: 'Начало',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 250,
      render: (_, record) => (
        <div>
          {dayjs(record.start_date).format(appDateFormatWithTime)}
        </div>
      ),
    },
    {
      title: 'Окончание',
      dataIndex: 'end_date',
      key: 'end_date',
      width: 250,
      render: (_, record) => (
        <div>
          { record.end_date ? dayjs(record.end_date).format(appDateFormatWithTime) : ''}
        </div>
      ),
    },
    {
      title: 'Обьект',
      dataIndex: 'nick',
      key: 'nick',
      render: (_, record) => (
        <div>
            <b>{record.nick}</b> ({record.carrier_old}&nbsp;
            { record.gender && +record.gender === 1 && `М`}
            { record.gender && +record.gender === 2 && `Ж`}
            )
        </div>
      ),
    },
    {
      title: 'Контекст',
      dataIndex: 'context_name',
      key: 'context_name',
    },
    {
      title: 'Эксперт',
      dataIndex: 'parent_name',
      key: 'parent_name',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      // filters: [
      //   {
      //     text: <Tag color="#f50">REC</Tag>,
      //     value: 1,
      //   },
      //   {
      //     text: <Tag color="#87d068">OK</Tag>,
      //     value: 2,
      //   },
      //   {
      //     text: <Tag color="#2db7f5">ERR</Tag>,
      //     value: 3,
      //   },
      // ],
      // filterMultiple: false,
      render: (_, record) => (
        <div>
          { record.status && +record.status === 1 && <Tag color="#f50">REC</Tag>}
          { record.status && +record.status === 2 && <Tag color="#87d068">OK</Tag>}
          { record.status && +record.status === 3 && <Tag color="#2db7f5">ERR</Tag>}
          { record.status && +record.status === 4 && <Tag color="blue">CALC</Tag>}
        </div>
      ),
    },
    {
      title: 'Графики',
      dataIndex: 'graphs',
      key: 'graphs',

      render: (_, record) => (
        <Checkbox.Group
          options={optionsGraph}
          // disabled
          defaultValue={record.graphs}
          onChange={(e) => onChangeGraphs(e, record)}
        />
      ),
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div>
          <Space>
          <Tooltip title="Перерасчет сессии">
            <Popconfirm
              title="Вы уверены, что хотите перерасчитать сессию?"
              onConfirm={() => {
                onRecalculateSession(record);
              }}
              okText="Да"
              cancelText="Нет"
            >
              <ReloadOutlined twoToneColor="blue" />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Удаление сессии">
          <Popconfirm
              title="Вы уверены, что хотите удалить сессию измерения?"
              onConfirm={() => {
                onDeleteSession(record);
              }}
              okText="Да"
              cancelText="Нет"
          >
            <DeleteTwoTone twoToneColor="red" />
          </Popconfirm>
          </Tooltip>
          <Button
            onClick={()=>{
              const info = localStorage.getItem(`session_graphs_${record.id}`,)
              let url = `/telemetry/sessions/${record.id}`
              if(info) {
                const graphs = JSON.parse(info);
                // console.log(graphs);
                const params = new URLSearchParams()
                // eslint-disable-next-line no-restricted-syntax
                for (const val of graphs) {
                  params.set(val, "1")
                }
                url = `${url}?${params.toString()}`
              }
              window.open(url, "_blank")
            }}
          >
            Показать
          </Button>
          <Popconfirm
              title="Вы уверены, что хотите завершить сессию?"
              onConfirm={() => {
                onCloseSession(record);
              }}
              okText="Да"
              cancelText="Нет"
          >
            <Button danger hidden>
              Завершить
            </Button>
          </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  // if (!getLocalAccessToken()) {
  //   return <RedirectLogin />;
  // }

  if (isError && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  // setData(sessions?.data);

  return (
      <Row>
        <Col span={4} style={{padding: 20}}>
          <h2>Поиск:</h2>
          <TelemetrySearchForm
              nick={filterNick}
              setNick={setFilterNick}
              gender={filterGender}
              setGender={setFilterGender}
              contexts={filterContexts}
              setContexts={setFilterContexts}
              markers={filterMarkers}
              setMarkers={setFilterMarkers}
              oldStart={filterCarrierOldStart}
              setOldStart={setFilterCarrierOldStart}
              oldEnd={filterCarrierOldEnd}
              setOldEnd={setFilterCarrierOldEnd}
          />
        </Col>
        <Col span={20}>
          <Table
              columns={columns}
              dataSource={[...(sessions?.data ?? [])] as SessionFrontendExtra[]}
              bordered rowKey="id"
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                showSizeChanger: true,
                pageSizeOptions: [DEFAULT_PAGE_SIZE, 30, 50, 100, 250, 500],
                current: page,
                position: ["bottomCenter"],
                pageSize,
                onChange:(newPage, newPageSize) => {
                  clearLocalKeys();
                  setPage(newPage);
                  setPageSize(newPageSize);
                },
                showTotal: showTotalRecords
              }}
              onChange={onChange}
              size="small"
          />
        </Col>
      </Row>
  );
};

export default Telemetry;
