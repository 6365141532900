import React from 'react';
import { Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';

const Dicts = () => {
  const data = [
    {
      title: 'Список контекстов',
      key: '/dicts/contexts',
    },
    {
      title: 'Идентификаторы смартфонов',
      key: '/account',
    },
    {
      title: 'Список измерительных сенсоров',
      key: '/sensor_list',
    },
    {
      title: 'Программы',
      key: '/program',
    },
  ];

  return (
    <Row>
      <Col span={12} offset={6}>
        <h1>Личный кабинет</h1>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={<Link to={item.key}>{item.title}</Link>} />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default Dicts;
