import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, Form, Input, notification, Popconfirm, Space, Spin, Table, Tooltip,
} from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table';
import axios, { AxiosError } from 'axios';
import {
  DeleteOutlined, EditOutlined, PlusSquareOutlined, RollbackOutlined, UnorderedListOutlined,
} from '@ant-design/icons';
import api, { axiosErrorHandler } from '../../http';
import useFetching from '../../hooks/useFetching';
import { SessionContext, SessionContextMarker, SessionContextMarkerCreate } from '../../models/types';
import { AddEditMarkerContextModal } from '../../components/UI/Context/Index';
import { FormModeEnum } from '../../models/enums';

const SessionChangeMarkers = () => {
  const { contextId } = useParams();
  const [openAddMarkerContextForm, setOpenAddMarkerContextForm] = useState(false);
  const [openEditContextForm, setOpenEditContextForm] = useState(false);
  const [currentMarker, setCurrentMerker] = useState<SessionContextMarker>();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutationCreateMarkerContext = useMutation(
    (data: SessionContextMarkerCreate) => api.v1.v1MarkersInContextCreate(contextId!, data),
  );
  const mutationEditMarkerContext = useMutation(
    (data: SessionContextMarkerCreate) => api.v1.v1MarkersInContextPartialUpdate(contextId!, data.id!.toString(), data),
  );
  const mutationDeleteMarkerContext = useMutation(
    (id: number) => api.v1.v1MarkersInContextDelete(contextId!, id.toString()),
  );

  const useContextInfo = () => {
    const {
      isLoading: isLoadingContxt,
      error: errorContext,
      data: contextData,
    } = useQuery({
      queryKey: ['contextRead'],
      queryFn: () => api.v1.v1ContextsRead(contextId!.toString()),
      enabled: !!contextId,
    });

    const {
      isLoading: isLoadingMarkers,
      error: errorMarkers,
      data: dataMarkers,
    } = useQuery({
      queryKey: ['markersListContext'],
      queryFn: () => api.v1.v1MarkersInContextList(contextId!.toString()),
      enabled: !!contextId && !!contextData,
    });

    const error = errorContext || errorMarkers;
    const isLoading = isLoadingContxt || isLoadingMarkers;

    return {
      error,
      isLoading,
      contextData,
      dataMarkers,
    };
  };

  const onCreateMarkerContext = (data: SessionContextMarkerCreate) => {
    mutationCreateMarkerContext.mutate(
      data,
      {
        onError: (err) => {
          if (axios.isAxiosError(err)) {
            const errorMessage = err.response?.data.detail;
            notification.error({ message: `Ошибка: ${errorMessage}` });
          }
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['markersListContext']);
          notification.success({ message: 'Новый маркер был добавлен' });
        },
      },
    );

    setOpenAddMarkerContextForm(false);
  };

  const onEditMarkerContext = (data: SessionContextMarkerCreate) => {
    mutationEditMarkerContext.mutate(
      data,
      {
        onError: (err) => {
          if (axios.isAxiosError(err)) {
            const errorMessage = err.response?.data.detail;
            notification.error({ message: `Ошибка: ${errorMessage}` });
          }
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['markersListContext']);
          notification.success({ message: 'Маркер был изменен' });
        },
      },
    );

    setOpenEditContextForm(false);
  };

  const onDeleteMarkerContext = (record: SessionContextMarkerCreate) => {
    if (record.id) {
      mutationDeleteMarkerContext.mutate(
        record.id,
        {
          onError: (err) => {
            if (axios.isAxiosError(err)) {
              const errorMessage = err.response?.data.detail;
              notification.error({ message: `Ошибка: ${errorMessage}` });
            }
          },
          onSuccess: () => {
            queryClient.invalidateQueries(['markersListContext']);
          },
        },
      );
    }
  };

  const columns: ColumnsType<SessionContextMarker> = [
    {
      title: 'Название',
      dataIndex: 'marker_name',
      key: 'marker_name',
    },
    {
      title: 'Порядковый номер в контексте',
      dataIndex: 'show_num',
      key: 'show_num',
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space wrap>
            <Button
              style={{ color: 'green', marginLeft: 12 }}
              onClick={() => {
                setCurrentMerker(record);
                setOpenEditContextForm(true);
              }}
            >Изменение события
            </Button>
            <Popconfirm
              title="Вы уверены, что хотите удалить маркер из контекста? "
              onConfirm={() => {
                onDeleteMarkerContext(record);
              }}
              okText="Да"
              cancelText="Нет"
            >
              <Tooltip title="Удаление маркера из контекста">
                <Button danger>Удалить
                </Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const {
    error, isLoading, contextData, dataMarkers,
  } = useContextInfo();

  if (error && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  return (
    <>
      <h1>Редактирование событий в контексте &quot;{contextData?.data.name}&quot;</h1>
      <Space wrap>
        <Button
          shape="round"
          style={{ borderColor: 'green', color: 'green' }}
          onClick={() => {
            setOpenAddMarkerContextForm(true);
          }}
        >Добавление события в контекст
        </Button>

        <Button
          shape="round"
          style={{ borderColor: 'blue', color: 'blue' }}
          onClick={() => { navigate('/dicts/contexts'); }}
        >Вернуться к списку контекстов
        </Button>
      </Space>
      <Table columns={columns} dataSource={dataMarkers?.data.markers} bordered rowKey="id" pagination={false} />
      <AddEditMarkerContextModal
        isVisible={openAddMarkerContextForm}
        modeForm={FormModeEnum.Create}
        initContext={contextData?.data}
        onSubmit={onCreateMarkerContext}
        onCancel={() => { setOpenAddMarkerContextForm(false); }}
      />
      <AddEditMarkerContextModal
        isVisible={openEditContextForm}
        modeForm={FormModeEnum.Edit}
        initContext={contextData?.data}
        initMarker={currentMarker}
        onSubmit={onEditMarkerContext}
        onCancel={() => { setOpenEditContextForm(false); }}
      />
    </>
  );
};

export default SessionChangeMarkers;
