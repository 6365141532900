import React, { useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { TrustedEmails, UserSerializerCreate } from '../../models/types';
import api, { apiWithoutInterceptor } from '../../http';
import appDateFormat from '../../consts';

const { Title } = Typography;

const RegistrationAccount = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  // const mutationSendCodeActivate = useMutation((email: string) => api.v1.v1ProfileSendCodeRead(email));
  const mutationSendCodeActivate = useMutation({
    mutationFn: apiWithoutInterceptor.v1.v1ProfileSendCodeRead,
    onSuccess: () => {
      notification.info({
        message: 'На почту было выслано письмо с кодом активации',
      });
    },
  });

  const onSubmit = async ({
    username,
    password,
    date_birth,
    gender,
    email,
    code,
    about,
  }: UserSerializerCreate) => {
    console.log('Submit:', username, email);

    try {
      const dateFormat = dayjs(date_birth).format(appDateFormat);

      const { data } = await apiWithoutInterceptor.v1.v1ProfileUsersCreate({
        username,
        password,
        date_birth: dateFormat,
        gender,
        email,
        code,
        about,
      });
      console.log('response', data);

      notification.info({ message: data.detail });

      navigate('/auth/login', { replace: true });
    } catch (err) {
      let errorMessage = 'Ошибка авторизации';
      if (axios.isAxiosError(err)) {
        errorMessage = err.response?.data.detail;
        console.log('Axios error', err.message, errorMessage);
      }

      notification.error({ message: errorMessage });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row>
      <Col span={12} offset={6}>
        <Title level={3} style={{ textAlign: 'center' }}>
          Регистрация учетной записи:
        </Title>

        <Form
          onFinish={onSubmit}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          // onValuesChange={onValuesChange}
        >
          <Form.Item
            label="Имя пользователя:"
            name="username"
            rules={[{ required: true, message: 'Введите имя пользователя' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Пароль:"
            name="password"
            rules={[
              { required: true, message: 'Введите свой пароль' },
              {
                min: 8,
                message: 'Пароль должен быть минимум длинной 8 символов',
              },
              {
                pattern: new RegExp(
                  '^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*\\d)(?=.*[@$!%*?&])[A-ZА-Яa-zа-я\\d@$!%*?&]{8,}$',
                ),
                message:
                  'Пароль должен состоять как минимум из букв в нижнем и верхнем регистре, числа и спец. символа @$!%*?&',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Пароль повторно:"
            name="confirm"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Введите свой пароль повторно' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Введенные пароли не совпадают!'),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label="Email" required>
            <Space align="baseline" style={{ width: '100%' }}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Введите свой Email',
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => {
                  return (
                    <Button
                      disabled={
                        !form.isFieldTouched('email')
                        || !!form.getFieldError('email').length
                      }
                      onClick={() => {
                        mutationSendCodeActivate.mutate(
                          form.getFieldValue('email'),
                        );
                      }}
                    >
                      Подтверждение Email
                    </Button>
                  );
                }}
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            label="Код подтверждения"
            name="code"
            rules={[
              {
                required: true,
                message: 'Введите код подтверждения из письма',
              },
            ]}
          >
            <Input style={{ width: 150 }} />
          </Form.Item>
          <Form.Item
            label="Дата рождения:"
            name="date_birth"
            rules={[
              { required: true, message: 'Введите дату своего рождения!' },
            ]}
          >
            <DatePicker format={appDateFormat} placeholder={appDateFormat} />
          </Form.Item>
          <Form.Item label="Пол:" name="gender" rules={[{ required: true }]}>
            <Select
              style={{ width: 120 }}
              options={[
                { value: '1', label: 'Мужской' },
                { value: '2', label: 'Женский' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="О себе"
            name="about"
            rules={[{ required: true, message: 'Укажите информацию о себе' }]}
          >
            <TextArea
              rows={4}
              placeholder="Укажите ключевую информацию для возможного предоставления вам доступа: о вашем виде деятельности, интересах, как узнали о проекте, для чего вам хочется использовать технологии Когнет"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="submit">
            <Button type="primary" htmlType="submit">
              Регистрация
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default RegistrationAccount;
