import {Form,Button, Col, Input, notification, Row, Spin, Typography} from "antd";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {apiWithoutInterceptor} from "../../http";

const {Title} = Typography;

const NewPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token} = useParams() as {
    token: string;
  };

  console.log("Token", token);

  const mutationValidateToken = useMutation({
    mutationFn: () => apiWithoutInterceptor.passwordReset.passwordResetValidateTokenCreate({token}),
    onError: () => {
      console.log("Не валидный токен", token);
      notification.warning({
        message: 'Неправильная ссылка на изменение пароля',
      });
      navigate("/", {replace: true});
    }
  })

  const mutationResetPassword = useMutation({
    mutationFn: (
      password: string) => apiWithoutInterceptor.passwordReset.passwordResetConfirmCreate({password, token}),
    onSuccess: () => {
      notification.info({
        message: 'Пароль был успешно изменен',
      });
      navigate("/", {replace: true});
    },
    onError: () => notification.error({message: "Ошибка системы при смене пароля"})
  })

  // проверим токен сразу
  useEffect(() => {
    mutationValidateToken.mutate();
  }, [])

  if (!mutationValidateToken.isSuccess){
    return (<div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>)
  }

  return (

    <Row>
      <Col span={12} offset={6}>
        <Title level={3} style={{textAlign: 'center'}}>
          Установка нового пароля
        </Title>
        <Form
          onFinish={() => {
            mutationResetPassword.mutate(
              form.getFieldValue('password'),
            );
          }}
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          form={form}
        >
          <Form.Item
            label="Пароль:"
            name="password"
            rules={[
              { required: true, message: 'Введите свой пароль' },
              {
                min: 8,
                message: 'Пароль должен быть минимум длинной 8 символов',
              },
              {
                pattern: new RegExp(
                  '^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*\\d)(?=.*[@$!%*?&])[A-ZА-Яa-zа-я\\d@$!%*?&]{8,}$',
                ),
                message:
                  'Пароль должен состоять как минимум из букв в нижнем и верхнем регистре, числа и спец. символа @$!%*?&',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Пароль повторно:"
            name="confirm"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Введите свой пароль повторно' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Введенные пароли не совпадают!'),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{offset: 8, span: 16}} name="submit">
            <Button type="primary" htmlType="submit">
              Сменить пароль
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default NewPassword;