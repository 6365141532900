import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Spin, Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { SensorExtra } from '../../models/types';
import api from '../../http';

const CarrierSensorsList = () => {
  const queryKey = ['CarrierSensorsList'];

  const columns: ColumnsType<SensorExtra> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: (a, b) => {
        if (a.id && b.id) {
          return a.id - b.id;
        }
        return 1;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: 'Носитель',
      dataIndex: 'nick',
      key: 'nick',
    },
    {
      title: 'Эксперт',
      dataIndex: 'parent',
      key: 'parent',
    },
    {
      title: 'Тип сенсора',
      dataIndex: 'sensor_type',
      key: 'sensor_type',
      render: (_, record) => (
        <>
          {record.sensor_type === 1 && <p>HeartRate</p> }
          {record.sensor_type === 2 && <p>GEO</p> }
        </>
      ),
    },
    {
      title: 'MAC адрес',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Фирменное название',
      dataIndex: 'manufacturer_name',
      key: 'manufacturer_name',
      filters: [
        {
          text: 'Zephyr',
          value: 'Zephyr',
        },
        {
          text: 'Polar',
          value: 'Polar',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => {
        console.log('HRM', value);
        if (typeof value === 'string') {
          console.log('HRM -string');
          return record.manufacturer_name.startsWith(value);
        }
        return true;
      }
    },
    {
      title: 'Пользовательское название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Серийный номер',
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
  ];

  const {
    isLoading,
    error,
    data: dataCarrierSensors,
  } = useQuery(queryKey, () => api.v1.v1SensorsListExtra());

  if (error && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}><Spin /></div>;
  }

  return (
    <>
      <h1>Список носителей с сенсорами</h1>
      <Table columns={columns} dataSource={dataCarrierSensors?.data} bordered rowKey="id" />
    </>
  );
};

export default CarrierSensorsList;
