import { Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { IUser } from '../../../models/IUser';
import { setUserData } from '../../../redux/slices/user';
import { useAppDispatch } from '../../../hooks/appStore';

const RedirectLogin = () => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    // сбросим пользователя на анонимуса
    const user: IUser = {
      id: 0,
      username: '',
      is_superuser: false,
    };
    appDispatch(setUserData(user));
  }, []);

  return <Navigate to="/auth/login" replace />;
};

export default RedirectLogin;
