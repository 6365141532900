const graphLabelName = (signalType:number) => {
  switch (signalType) {
    case 1:
      return 'RRRaw';
    case 2:
      return 'HR';
    case 3:
      return 'GEO';
    case 4:
      return 'Energy';
    case 5:
      return 'RR';
    case 6:
      return 'RRFilter';
    case 7:
      return 'Spectr';
    case 11:
      return 'ECG';
    case 16:
      return "PPG";
    default:
      return 'UNKNOWN';
  }
};

export const findClosestDateIndex = (dates: Date[], targetDate: Date) => {
  // находим ближайшую дату к заданной
  let minDiff = Infinity;
  let closestIndex = -1;

  for (let i = 0; i < dates.length; i+=1) {
    const diff = Math.abs(dates[i].getTime() - targetDate.getTime());
    if (diff < minDiff) {
      minDiff = diff;
      closestIndex = i;
    }
  }

  return closestIndex;
}


export function getFirstValue<T>(keyOrKeys: T | T[]): T | undefined {
  if (Array.isArray(keyOrKeys)) {
    return keyOrKeys.length > 0 ? keyOrKeys[0] : undefined;
  }
  return keyOrKeys;
}

export default graphLabelName;
